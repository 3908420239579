.inputWrapper input {
  // flex: 1;
  width: 272px;
  background-color: transparent;
  outline: none;
  border-radius: 10px;
  border: 0.8px solid #fff;

  &.bordered {
    border: 1px solid #DC633B;
    border-radius: 5px;
  }

  &.autoWidth {
    width: 95%;
  }

  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px;
    &::placeholder{
        opacity: 1;
        color: #000;
    }
}

//   @media screen and (max-width: 576px) {
//     .inputWrapper input {
//       // flex: 1;
//       padding: 8px 0;
//       font-size: 16px;
//       letter-spacing: -0.48px;
//     }
//   }
