h1 {
    color: #505050;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    text-align: center;
    // padding-bottom: 50px;
}

.spacing {
    padding: 80px 0px;
}
.wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 50px;

    @media screen and (max-width: 576px) {

    grid-template-columns: repeat(1, 1fr);
    }

    .main {
        // flex: 1;
        // display: flex;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // flex-wrap: wrap;
        @media screen and (max-width: 576px) {

            grid-template-columns: repeat(1, 1fr);
            }

        .texts {
            background-color: #DC633B;
            // flex: 1;
            color: #ffffff;
            text-align: left;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 128%;
            padding: 54px 31px;

            @media screen and (max-width: 576px) {
                padding-bottom: 54px;
            }
        }

        .smallText {
            font-size: 18px;
        }

        .person {
            // flex: 0.5;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
