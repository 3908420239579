.wrapper {
    padding-top: 180px;
    position: relative;
    background: white;

    @media screen and (max-width: 576px) {
        flex-direction: column;
        padding-top: 150px;
    }

    .pattern {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        height: 460px;
        background: #DC633B;

        @media screen and (max-width: 576px) {
            height: 500px;
        }
    }

    .left {
        z-index: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 50px;

        @media screen and (max-width: 576px) {
            padding-left: 16px;
            padding-right: 16px;
        }

        h1 {
            color: #000;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            text-align: center;
        }

        .alter {
            color: white;
        }

        p {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            padding: 40px 0px;
            text-align: center;
        }

        .btnCont {
            margin: 50px auto;
        }

        .news {
            border-radius: 28px;
            border: 1.5px solid #DC633B;
            padding: 50px 200px;
            margin-top: 120px;
            text-align: center;

            h1 {
                color: #000;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 40px */
            }

            p {
                color: #000;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 25px 0px;
            }

            .buttonWrapper {
                margin: 0 auto;
                width: 457px;
                background: #fff0e5;
                padding: 15px 20px;
                border-radius: 14.5px;
                display: flex;
                justify-content: space-between;
                gap: 15px;

                input {
                    color: #606060;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.08px;
                    border: none;
                    width: 100%;
                    outline: none;
                    background: transparent;
                    // padding: 10px 15px;
                }

                .icon {
                    transform: rotate(180deg);
                    cursor: pointer;
                }
            }


            @media screen and (max-width: 576px) {
                border-radius: 28px;
                border: 1.5px solid #DC633B;
                padding: 30px 16px;
                margin-top: 120px;
                margin: 80px 16px 0px;
                text-align: center;
            
                h1 {
                    color: #000;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    /* 40px */
                }
            
                p {
                    color: #000;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 25px 0px;
                }
            
                .buttonWrapper {
                    margin: 0 auto;
                    width: auto;
                    background: #FFF0E5;
                    padding: 15px 20px;
                    border-radius: 14.5px;
                    display: flex;
                    justify-content: space-between;
                    gap: 15px;
            
                    input {
                        color: #606060;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 1.08px;
                        border: none;
                        width: 100%;
                        outline: none;
                        background: transparent;
                        // padding: 10px 15px;
                    }
            
                    .icon {
                        transform: rotate(180deg);
                        cursor: pointer;
                    }
                }
            }
        }

        .cardCont {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 100px 40px;
            margin-bottom: 200px;

            @media screen and (max-width: 576px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));

            }

            .card {
                background: #FFF0E5;
                height: 350px;
                border-radius: 20px;
                position: relative;
                padding: 0px 16px;
                cursor: pointer;

                h3 {
                    color: #B50D0D;
                    padding-top: 40px;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    text-align: center;
                }

                img {
                    position: absolute;
                    left: 73px;
                    top: 140px;
                    height: 268px;
                    width: 195px;

                    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);

                    @media screen and (max-width: 576px) {
                        left: 98px;
                    }
                }
            }
        }

        .buttonCont {
            display: flex;
            background: #fff;
            padding: 8px 20px;
            border-radius: 16px;
            max-height: 50px;
            width: 500px;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


            @media screen and (max-width: 576px) {
                width: 350px;
            }

            input {
                color: #606060;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.08px;
                border: none;
                width: 200px;
                outline: none;
                // padding: 10px 15px;
            }
        }
    }

    .right {
        z-index: 1;
        flex: 0.4;
        padding-right: 50px;

        display: flex;
        justify-content: center;
        flex-direction: column;

        .mainImage {
            width: 423px;
            margin: auto;
        }
    }
}
