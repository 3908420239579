.wrapper {
  position: relative;

  .bgContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #151515;
    height: 45vh;
  }

  .bgContainerBottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #151515;
    height: 120vh;
  }

  .mainContent {
    // text-align: center;

    .partners {
      padding: 28px 0 80px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 77px;

      .logoImage {
        width: 145px;
        height: 35px;
      }
    }

    .appointment {
      border-radius: 19px;
      border: 1.5px solid #000;
      padding: 40px 117px;
      background: #fff;
      display: flex;
      gap: 50px;

      .left {
        text-align: left;

        .topText {
          color: #DC633B;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .header {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 20px 0px 40px;
        }

        .buttonCont {
          width: 226px;
        }
      }

      .right {
        margin: auto 0;

        .logoImage {
          width: 159px;
          height: 165.5px;
        }
      }
    }

    .requirement {
      margin-top: 75px;

      .headerSection {
        h1 {
          color: #2c2c2c;
          text-align: center;
          font-size: 48px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 48px */
        }

        .buttonCont {
          max-width: fit-content;
          margin: 30px auto;
        }

        p {
          color: #434343;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 114.5%;
          /* 22.9px */
          /* 28.32px */
          max-width: 833px;
          margin: 0 auto;
        }
      }

      .cardsSection {
        display: flex;
        gap: 27.5px;
        margin-top: 40px;

        .cards {
          border-radius: 19.685px;
          border: 0.656px solid #DC633B;
          padding: 16px 28px;
          flex: 1;

          .logoImage {
            width: 39.37px;
            height: 39.37px;
          }

          h2 {
            color: #000;
            text-align: left;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            margin: 14px 0px;
          }

          p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            text-align: left;
            font-weight: 400;
            line-height: 100%;
            /* 14px */
          }
        }
      }
    }

    .sureSection {
      margin-top: 75px;

      .headerSection {
        h1 {
          color: #161616;
          text-align: center;
          font-size: 56px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 56px */
          /* 48px */
        }

        .buttonCont {
          max-width: fit-content;
          margin: 30px auto;
        }

        p {
          color: #161616;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 118%;
          /* 28.32px */
          /* 22.9px */
          /* 28.32px */
          max-width: 838px;
          margin: 0 auto;
        }
      }

      .cardsSection {
        display: flex;
        gap: 20px;
        margin-top: 20px;

        .cards {
          border-radius: 15.412px;
          border: 1px solid #000;
          background: #fff;
          padding: 20px 0px 20px 20px;

          .logoImage {
            width: 50px;
            height: 50px;
          }

          h2 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            /* 24px */
            margin: 15px 0px;
          }

          p {
            color: #000;
            text-align: left;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            /* 14px */
          }

          .buttonCont {
            border-radius: 27px;
            border: 0.5px solid #000;
            padding: 10px 0px;
            width: 121px;
            text-align: center;
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 15px;
          }

          hr {
            margin: 15px 0px;
            width: 150px;
          }

          h2 {
            color: #DC633B;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          ul {
            margin-left: 20px;

            li {
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }

    .clientSection {
      position: relative;
      margin: 100px 0px 0px;
      padding-bottom: 60px;

      .topSection {
        display: flex;
        gap: 124px;

        .left {
          .header {
            color: #fff;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 56px */
          }

          .desc {
            color: #fff;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 24px */
            margin: 50px 0px;
          }

          .buttonCont {
            width: 160px;
          }
        }

        .right {
          .card {
            padding: 30px 30px 40px;
            border: 2px solid #DC633B;
            width: 450px;
            background: #fff;

            .icon {
              width: 70px;
              height: 70px;
            }

            h3 {
              color: #161616;
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 32px */
              margin: 30px 0px 20px;
              width: 220px;
            }

            p {
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 124%;
              /* 22.32px */
            }
          }

          .bottomSec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .right {
              color: #fff;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .left {
              color: #fff;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 24px */
            }
          }
        }
      }
    }
  }
}
