.inputWrapper {
    // flex: 1;
    width: 500px;
    padding: 5px 10px 5px 20px;
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid #000;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    input {
        color: #606060;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.08px;
        border: none;
        width: 200px;
        outline: none;
        // padding: 10px 15px;
    }

    .button {
        border-radius: 9px;
        background: #DC633B;
        padding: 10.5px 38.5px;
        color: #444;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
    }

}


  @media screen and (max-width: 576px) {
    .inputWrapper {
        // flex: 1;
        width: 100%;
        padding: 5px 5px 5px 20px;
        border-radius: 10px;
        gap: 30px;

        input {
            font-size: 14px;
            width: 160px;
            outline: none;
            background: transparent;
            // padding: 10px 15px;
        }

        .button {
            padding: 10.5px 38.5px;
            font-size: 14px;
        }

    }
  }
