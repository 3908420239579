.wrapper {
    .bgCont {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 295px;
        background: #FFEEE2;
    }

    .header {
        padding-top: 150px;
        padding-bottom: 150px;
        p {
            color: #606060;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-bottom: 18px;
        }

        h1 {
            color: #F87A23;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }
    }

    .main {
        padding: 0px 100px;
        margin-bottom: 80px;
        p {
            color: #333333;
            text-align: left;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }

        h3 {
            color: #333333;
            text-align: left;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            padding-top: 30px;
            padding-bottom: 10px;
        }
    }

    .collabBanner {
        border-radius: 15px;
        background: #DC633B;
        width: 903px;
        padding: 44px 90px 50px;
        margin: auto;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .rocket {
            position: absolute;
            left: 20px;
            top: -20px;
        }

        .pattern {
            position: absolute;
            right: 0;
            bottom: 0;
            border-bottom-right-radius: 15px;
        }

        h2 {
            color: #FFF;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.42px;
        }

        .button {
            display: inline-flex;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
            z-index: 2;
            cursor: pointer;

            p {
                color: #363636;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;
            }

            .arrow {
                width: 24px;
                height: 24px;

                transform: rotate(180deg);
            }
        }
    }
}
