.wrapper {
    padding-bottom: 80px;

    .topSection {
        display: flex;
        justify-content: space-between;
        padding-top: 100px;
        padding-bottom: 50px;

        h1 {
            color: #5C5C5C;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .faqsCont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 35px;
        padding-bottom: 100px;

        .items {
            flex: 0 42%;
            padding: 20px 35px;
            align-items: center;
            border-radius: 10px;
            border: 1px solid #DC633B;
            cursor: pointer;
            align-self: flex-start;

            .header {
                display: flex;
                justify-content: space-between;
                p {
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .arrow {
                    width: 24px;
                    height: 24px;
                    fill: #000;
                    transform: rotate(180deg);
                }

                .oppo {

                    transform: rotate(0deg);
                }
            }

            .answer {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
                transition:
                  max-height 0.3s ease,
                  opacity 0.3s ease,
                  padding-top 0.3s ease;

                // color: var(--primary-black);

                // @media screen and (max-width: 576px) {
                //   font-size: 16px;
                //   letter-spacing: normal;
                //   line-height: normal;
                // }
              }

              &:nth-child(n).active .answer {
                padding-top: 18px;
                max-height: 1000px; // Adjust this value according to your content
                opacity: 1;
              }


        }
    }

    .collabBanner {
        border-radius: 15px;
        background: #DC633B;
        width: 903px;
        padding: 44px 90px 50px;
        margin: auto;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .rocket {
            position: absolute;
            left: 20px;
            top: -20px;
        }

        .pattern {
            position: absolute;
            right: 0;
            bottom: 0;
            border-bottom-right-radius: 15px;
        }

        h2 {
            color: #FFF;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.42px;
        }

        .button {
            display: inline-flex;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
            z-index: 2;
            cursor: pointer;

            p {
                color: #363636;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;
            }

            .arrow {
                width: 24px;
                height: 24px;

                transform: rotate(180deg);
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        .topSection {
            align-items: center;
            flex-direction: column;
            padding-top: 60px;
            padding-bottom: 20px;
            gap: 20px;

            h1 {
                font-size: 24px;
            }
        }

        .faqsCont {
            gap: 20px;
            flex-direction: column;
            padding-bottom: 100px;
            padding-right: 15px;
            padding-left: 15px;

            .items {
                flex: 1;
                padding: 10px 15px;
                align-items: center;
                border-radius: 5px;

                p {
                    font-size: 12px;
                }

                .arrow {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .faqsCont {
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
            padding-bottom: 100px;
            padding-right: 15px;
            padding-left: 15px;

            .items {
                flex: 0 42%;
                padding: 10px 15px;
                align-items: center;
                border-radius: 10px;
                border: 1px solid #DC633B;
                cursor: pointer;
                align-self: flex-start;

                .header {
                    display: flex;
                    justify-content: space-between;
                    p {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .arrow {
                        width: 18px;
                        height: 18px;
                        fill: #000;
                        transform: rotate(180deg);
                    }

                    .oppo {

                        transform: rotate(0deg);
                    }
                }

                .answer {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    font-size: 14px;
                    transition:
                      max-height 0.3s ease,
                      opacity 0.3s ease,
                      padding-top 0.3s ease;

                    // color: var(--primary-black);

                    // @media screen and (max-width: 576px) {
                    //   font-size: 16px;
                    //   letter-spacing: normal;
                    //   line-height: normal;
                    // }
                  }

                  &:nth-child(n).active .answer {
                    padding-top: 18px;
                    max-height: 100px; // Adjust this value according to your content
                    opacity: 1;
                  }


            }
        }


        .collabBanner {
            border-radius: 15px;
            background: #DC633B;
            width: 280px;
            padding: 57px 24px 96px;
            flex-direction: column;
            text-align: center;


            .rocket {
                position: absolute;
                left: 20px;
                top: -20px;
            }

            .pattern {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 300px;
                border-bottom-right-radius: 15px;
            }

            h2 {
                font-size: 24px;
            }

            .button {
                margin-top: 10px;
            }
        }
    }
}
