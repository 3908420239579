.wrapper {
    position: relative;

    .bgContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: #DC633B;
        height: 50vh;
    }

    .bgContainerBottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 131px;
        background: #151515;
        height: 1000px;

        @media screen and (max-width: 576px) {
            height: 1200px;
            bottom: 115px;
        }
    }

    .mainContent {
        // text-align: center;

        .partners {
            display: flex;
            justify-content: space-between;
            padding: 35px 0;

            .logoImage {
                width: 145px;
                height: 35px;
            }
        }

        .appointment {
            border-radius: 19px;
            border: 1.5px solid #DC633B;
            padding: 40px 117px;
            background: #FFF;
            display: flex;
            gap: 50px;
            margin-top: 50px;
            margin-bottom: 100px;

            .left {
                text-align: left;

                .topText {
                    color: #DC633B;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .header {
                    color: #000;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin: 20px 0px 40px;
                }

                .buttonCont {
                    width: 226px;
                }
            }

            .right {
                margin: auto 0;

                .logoImage {
                    width: 159px;
                    height: auto;
                    aspect-ratio: 1/1;
                }
            }

            @media screen and (max-width: 576px) {
                flex-direction: column;
                margin: 0 16px;
                padding: 25px 20px;
                margin-bottom: 100px;

                .left {
                    text-align: center;

                    .header {
                        font-size: 24px;
                        margin: 20px 0px 40px;
                    }

                    .buttonCont {
                        width: 226px;
                        margin: auto;
                    }
                }

                .right {
                    margin: auto;

                    .logoImage {
                        width: 159px;
                        height: 165.5px;
                    }
                }
            }
        }

        .whatSection {
            padding-top: 100px;
            padding-bottom: 50px;

            .header {
                color: #161616;
                text-align: center;
                font-size: 48px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 48px */
                margin-bottom: 40px;
            }

            .content {
                display: flex;
                justify-content: space-between;
                gap: 105px;

                .left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .first {
                        color: #DC633B;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        /* 24px */
                    }

                    .second {
                        color: #222;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 106%;
                        /* 19.08px */
                        margin: 30px 0px;
                    }

                    .list {


                        .items {

                            display: flex;
                            gap: 16px;
                            justify-content: flex-start;
                            align-items: center;
                            margin: 22px 0px;

                            .stars {
                                width: 23px;
                                height: 23px;
                            }

                            p {
                                color: #4A4A4A;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 100%;
                                /* 18px */
                            }
                        }
                    }

                }

                .right {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .items {
                        flex: 1;
                        border-radius: 15px;
                        border: 1px solid #000;
                        background: #fff;
                        // height: 204px;
                        padding: 50px 22px;

                        &.active {
                            background: #DC633B;
                            border-color: #DC633B;

                            p {
                                color: #fff;
                            }
                        }

                        &.one {
                            min-width: 233px;
                            transform: translateY(50px);
                            padding: 22px;
                        }

                        &.two {
                            max-height: 204px;
                        }

                        &.three {
                            transform: translateY(25px);
                            padding: 36px 22px;
                        }

                        h2 {
                            color: #1e1e1e;
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            max-width: 120px;
                        }

                        p {
                            color: #252525;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            /* 24px */
                            max-width: 120px;
                        }
                    }
                }
            }
        }

        .easySection {

            text-align: center;
            margin-top: 80px;

            h1 {
                color: #FFF;
                font-size: 42px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            p {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-top: 4px;
            }

            .easyContainer {
                margin-top: 38px;
                padding: 20px 30px;
                border-radius: 25px 25px 0px 0px;
                background: #FFF;
                position: relative;
                display: inline-flex;
                flex-direction: column;
                gap: 97px;
                overflow: hidden;

                .easyWrapper {
                    display: flex;
                    gap: 50px;
                    justify-content: space-between;
                    z-index: 1;

                    .easyItem {
                        max-width: 234px;

                        .icon {
                            height: 30px;
                            width: 30px;
                            margin-bottom: 18px;
                        }

                        p {
                            color: #000;
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }

                .patternOne {
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: 0;
                }


            }
        }


        .sureSection {
            margin-top: 75px;

            .headerSection {

                h1 {
                    color: #161616;
                    text-align: center;
                    font-size: 56px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    /* 56px */
                    /* 48px */
                }

                .buttonCont {
                    max-width: fit-content;
                    margin: 30px auto;
                }

                p {
                    color: #161616;
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 118%;
                    /* 28.32px */
                    /* 22.9px */
                    /* 28.32px */
                    max-width: 838px;
                    margin: 0 auto;
                }
            }

            .cardsSection {
                display: flex;
                gap: 20px;
                margin-top: 20px;

                .cards {
                    border-radius: 15.412px;
                    border: 1px solid #000;
                    background: #FFF;
                    padding: 20px 0px 20px 20px;

                    .logoImage {
                        width: 50px;
                        height: 50px;
                    }

                    h2 {
                        color: #000;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        /* 24px */
                        margin: 15px 0px;

                    }

                    p {
                        color: #000;
                        text-align: left;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        /* 14px */

                    }

                    .buttonCont {
                        border-radius: 27px;
                        border: 0.5px solid #000;
                        padding: 10px 0px;
                        width: 121px;
                        text-align: center;
                        color: #000;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-top: 15px;
                    }

                    hr {
                        margin: 15px 0px;
                        width: 150px;
                    }

                    h2 {
                        color: #DC633B;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    ul {
                        margin-left: 20px;

                        li {
                            color: #000;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
        }


        .clientSection {
            position: relative;
            margin: 100px 0px 0px;
            padding-bottom: 60px;

            .topSection {
                display: flex;
                gap: 124px;

                .left {
                    .header {
                        color: #FFF;
                        font-size: 56px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 56px */
                    }

                    .desc {
                        color: #FFF;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        /* 24px */
                        margin: 50px 0px;
                    }

                    .buttonCont {
                        width: 160px;
                    }
                }

                .right {
                    .card {
                        padding: 30px 30px 40px;
                        border: 2px solid #DC633B;
                        width: 450px;
                        background: #FFF;

                        .icon {
                            width: 70px;
                            height: 70px;
                        }

                        h3 {
                            color: #161616;
                            font-size: 32px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            /* 32px */
                            margin: 30px 0px 20px;
                            width: 220px;
                        }

                        p {
                            color: #000;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 124%;
                            /* 22.32px */
                        }
                    }

                    .bottomSec {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 10px;

                        .right {
                            color: #fff;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        .left {
                            color: #fff;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            /* 24px */
                        }
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        .dotsMain {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            width: 100vw;

            li {
                display: inline-block;
                align-items: center;

                // float: left;
            }

            // li

            li button {
                // display: none;
                background: #DC633B;
                border-radius: 10px;
                width: 10px;
                height: 10px;
                margin: 10px 5px;
                color: transparent;
                border: 0px;

            }
        }

        .mainContent {
            .partners {
                .logoImage {
                    height: 70px;
                    width: 95px;
                    padding: 0px 12px;
                }
            }

            .easySection {
                margin-top: 20px;

                h1 {
                    font-size: 24px;

                }

                p {
                    font-size: 14px;
                    padding: 0px 16px;
                }

                .easyContainer {
                    background-color: transparent;
                    border-radius: 0px;
                    padding: 20px 0px;
                    overflow: visible;


                    .demoItem {
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                        max-width: 234px;
                        padding: 20px 30px;
                        border-radius: 36px;
                        align-items: center;
                        background: white;
                        margin: 10px 27.5px;




                        .icon {
                            height: 30px;
                            width: 30px;
                            // margin-bottom: 18px;
                            align-self: flex-start;
                            margin: 0 auto 18px;

                        }

                        p {
                            color: #000;
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}
