.wrapper {
    background: #DC633B;

    .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 130px;

        .mainText {
            color: #000;
            text-align: center;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 56px */

            .highlighted {
                color: #FFF;
            }

            padding: 0 100px;
        }

        p {
            color: #FFF;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 40px 0 33px;
            padding: 0 300px;
        }
    }
}


@media screen and (max-width: 576px) {
    .wrapper {
        background: #DC633B;

        .content {
            padding-top: 150px;

            .mainText {
                font-size: 24px;
                /* 56px */

                .highlighted {
                    color: #FFF;
                }

                padding: 0 16px;
            }

            p {
                font-size: 18px;
                margin: 40px 0 33px;
                padding: 0 16px;
            }
        }
    }
}
