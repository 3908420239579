.mainWrapper {
  position: relative;
  padding-top: 90px;
  padding-bottom: 130px;
  background: #1b1b1b;

  .wrapper {
    text-align: center;

    h1 {
      color: #fff;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .valueCards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 30px;
      margin-top: 60px;

      .valueCard {
        border-radius: 20px;
        padding: 20px 30px 0px 30px;
        max-width: 300px;
        background: #fff;
display: flex;
        flex-direction: column;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
          }

          .valueItem {
            height: 37px;
            width: 37px;
          }
        }

        p {
          color: #000;
          text-align: justify;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 128%;
          /* 23.04px */
          margin-top: 25px;
          margin-bottom: 5px;
        }
        .bottomImage{
          width: 100%;
          height: auto;
          object-fit: cover;
          margin-top: auto;
        }
      }
    }
  }
}

.differentText {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 900px;
  margin: 100px auto 80px;

  span {
    color: #DC633B;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.bannerOne {
  width: 100%;
  object-fit: contain;
}

.founderSection {
  padding: 100px 0px;

  .header {
    color: #000;
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    span {
      color: #f87a23;
    }
  }

  p {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 800px;
    margin: 0 auto;
    padding-top: 35px;
  }

  .founderCards {
    display: flex;
    gap: 60px;
    margin-top: 30px;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: center;
    }

    .founderCard {
      width: 400px;
      height: 500px;
      position: relative;
      display: flex;

      .founder {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      .texts {
        position: relative;
        margin: 25px;
        text-align: left;
        background: #fff;
        padding: 12px 15px 10px;
        align-self: flex-end;
        flex: 1;

        .name {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .position {
          color: #000;
          font-size: 24px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.collabBanner {
  border-radius: 15px;
  background: #DC633B;
  width: 903px;
  padding: 44px 90px 50px;
  margin: auto;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 130px;
  margin-bottom: 80px;

  .rocket {
    position: absolute;
    left: 20px;
    top: -20px;
  }

  .pattern {
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 15px;
  }

  h2 {
    color: #fff;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
  }

  .button {
    display: inline-flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 3;
    cursor: pointer;

    p {
      color: #363636;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    .arrow {
      width: 24px;
      height: 24px;

      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: 576px) {
  .mainWrapper {
    padding: 60px 16px 65px;

    .wrapper {
      h1 {
        font-size: 24px;
      }

      .valueCards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 8px;
        margin-top: 35px;

        .valueCard {
          border-radius: 10px;
          padding: 10px 11px;
          max-width: 138px;

          .top {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: flex-start;

            h2 {
              font-size: 14px;
              text-align: left;
              margin-top: 5px;
            }

            .valueItem {
              height: 21px;
              width: 21px;
            }
          }

          p {
            font-size: 10px;
            line-height: 128%;
            text-align: left;
            /* 23.04px */
            margin-top: 10px;
          }
        }
      }
    }
  }

  .differentText {
    font-size: 24px;
    width: auto;
    margin: 50px auto 30px;
    padding: 0 16px;

    span {
      font-size: 24px;
    }
  }

  .bannerOne {
    width: 360px;
    height: 450px;
    object-fit: cover;
  }

  .founderSection {
    padding: 50px 16px 60px;

    .header {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      width: auto;
      margin: 0 auto;
      padding-top: 30px;
    }

    .founderCards {
      margin-top: 20px;

      .founderCard {
        width: 300px;
        height: 380px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

        .founder {
          width: 300px;
          height: 310px;
        }

        .texts {
          position: relative;
          margin: 0px;
          text-align: left;
          background: #fff;
          padding: 12px 15px 10px;
          align-self: flex-end;
          flex: 1;

          .name {
            font-size: 20px;
          }

          .position {
            font-size: 18px;
          }
        }
      }
    }
  }

  .collabBanner {
    border-radius: 15px;
    width: auto;
    padding: 57px 24px 97px;
    margin: auto 16px;
    flex-direction: column;
    gap: 10px;
    margin-top: 70px;
    margin-bottom: 40px;

    .rocket {
      position: absolute;
      left: 20px;
      top: -20px;
    }

    .pattern {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 327px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    h2 {
      font-size: 24px;
      text-align: center;
    }
  }
}
