.wrapper {
    .headerSection {
        h1 {
            color: #000;
            text-align: center;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
            margin-top: 27px;
        }

        p {
            color: #000;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }


    .cardsSection {
        display: flex;
        gap: 20px;
        margin: 50px 0 60px;

        .cards {
            border-radius: 15.412px;
            border: 0.4px solid #000;
            background: #FFF;
            padding: 20px 0px 20px 30px;

            .logoImage {
                width: 50px;
                height: 50px;
            }

            h2 {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 15px 0px;

            }

            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 128%;
                /* 20.48px */

            }

            .price {
                color: #000;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 15px 0;
            }

            .buttonCont {
                max-width: 130px;
            }

            hr {
                margin: 15px 0px;
                width: 150px;
            }

            ul {
                margin-left: 20px;
                margin-top: 25px;

                li {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    margin: 5px 0;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        .headerSection {
            padding-top: 80px;
            padding-left: 16px;
            padding-right: 16px;
            h1 {
                font-size: 32px;
            }

            p {
                font-size: 14px;
            }
        }

        .cardsSection {
            flex-direction: column;
            padding: 0px 16px;
        }
    }
}