@font-face {
  font-family: "Satoshi";
  src: url("../public/fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../public/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500; /* or 'medium' */
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../public/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../public/fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Satoshi", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  max-width: 100vw;
  background: var(--primary-white);
  /* overflow-x: hidden; */
}

@media screen and (max-width: 576px) {
  html, body {
    overflow-x: hidden;
  }
}
a {
  text-decoration: none;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* .slick-track {
  display: flex;
  align-items: stretch;

  .slick-slide {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .heroImage {
      max-height: 200px;
      width: auto;
      object-fit: cover;
    }
  }
} */
