.topWrapper {
  position: relative;
}
.wrapper {
  padding-top: 120px;

  .header {
    .top {
      color: #000;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 32px */
    }

    .middle {
      color: #000;
      text-align: center;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 56px */
      margin: 30px 0;
    }

    .bottom {
      color: #000;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 30px */
      margin-bottom: 50px;
    }

    .heroIllus {
      width: 100%;
    }
  }
  .authorCard {
    border-radius: 28px;
    border: 1.5px solid #DC633B;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    .author {
      // width: 150px;
      // height: 150px;
      object-fit: cover;
      border-radius: 20px;
    }

    .texts {
      margin: 0px 20px;

      h3 {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 24px */
        margin-bottom: 15px;
      }

      p {
        color: #000;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .icons {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-bottom: 20px;

      .icon {
        cursor: pointer;
      }
    }
  }

  .bodyMain {
    display: flex;
    gap: 25px;
    padding-top: 50px;
    position: relative;

    .left {
      .texts {
        color: #434343;
        text-align: justify;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 36px */
        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-bottom: 20px;
        }

        h1 {
          color: #000;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 134.5%;
          /* 53.8px */
          margin-bottom: 20px;
          color: var(--primary-black);
        }

        p {
          color: #494949;

          text-align: justify;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin-bottom: 20px;
          /* 27px */
        }

        hr {
          margin: 20px 0;
        }

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
          ol,ul{
              li{
                  margin-bottom: 20px;
              }
          }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 50px 0 60px;

        .first {
          color: #393939;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 124.5%;
          /* 29.88px */
          letter-spacing: -0.48px;
        }

        .line {
          height: 1px;
          width: 358px;
          background: #565656;
        }

        .icons {
          display: flex;
          gap: 20px;

          .icon {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
    }

    .right {
      position: relative;
      // height: 1000px;
      .contact {
        border-radius: 28px;
        border: 1.5px solid #DC633B;
        padding: 50px 39px;
        margin-bottom: 36px;
        min-width: 300px;
        position: sticky;
        top: 120px;

        h2 {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 24px */
        }

        p {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 25px 0;
        }

        .button {
          display: flex;
          justify-content: space-between;
          padding: 0px 25px;
          border-radius: 14.5px;
          background: #fff0e5;
          align-items: center;

          p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
          }

          .arrow {
            width: 24px;
            height: 24px;
            transform: rotate(180deg);
          }
        }
      }

      .contents {
        border-radius: 28px;
        border: 1.5px solid #DC633B;
        padding: 30px 50px 50px 40px;

        h3 {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 24px */
          margin-bottom: 25px;
        }

        p {
          color: #838383;

          font-family: Satoshi;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 15px;
        }

        .active {
          color: #DC633B;
        }

        hr {
          margin-bottom: 25px;
        }
      }
    }
  }

  .blogList {
    margin-top: 150px;
    .header {
      color: #000;
      text-align: center;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      /* 63px */
      margin-bottom: 30px;
    }

    .middleBlogs {
      display: flex;
      gap: 31px;
      flex-wrap: nowrap;
      padding-bottom: 100px;
      justify-content: center;

      .blogCard {
        display: flex;
        flex-direction: column;
        gap: 35px;
        // align-items: center;
        margin-bottom: 30px;
        border: 1px solid #000;

        background: #fff;
        padding: 20px 40px;
        cursor: pointer;

        .heroImage {
          width: 275px;
          height: 270px;
        }

        .details {
          .title {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 24px */
          }

          .type {
            color: #DC633B;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
            margin: 15px 0 7px;
          }

          .author {
            color: #3a3a3a;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 16px */
            margin-bottom: 8px;
          }

          .date {
            color: #6d6d6d;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 12px */
          }
        }
      }

      @media screen and (max-width: 576px) {
        display: block;

        .blogCard {
          max-width: 85vw;
          .heroImage {
            width: 100%;
          }
        }
      }
    }
  }

  .news {
    border-radius: 28px;
    border: 1.5px solid #DC633B;
    padding: 50px 290px;
    margin-bottom: 60px;
    text-align: center;

    h1 {
      color: #000;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 40px */
    }

    p {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 25px 0px;
    }

    .buttonWrapper {
      margin: 0 auto;
      width: 457px;
      background: #fff0e5;
      padding: 15px 20px;
      border-radius: 14.5px;
      display: flex;
      justify-content: space-between;
      gap: 15px;

      input {
        color: #606060;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.08px;
        border: none;
        width: 100%;
        outline: none;
        background: transparent;
        // padding: 10px 15px;
      }

      .icon {
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    .header {
      .top {
        padding-left: 16px;
        padding-right: 16px;
        font-size: 18px;
      }

      .middle {
        padding-left: 16px;
        padding-right: 16px;
        font-size: 24px;
      }

      .bottom {
        padding-left: 16px;
        padding-right: 16px;
        font-size: 14px;
      }

      .heroIllus {
        width: 100%;
        height: 600px;
        object-fit: fill;
      }
    }

    .bodyMain {
      flex-direction: column;
      .left {
        padding: 0px 16px;
        .texts {
          font-size: 16px;
        }

        .bottom {
          .first {
            font-size: 18px;
          }
          .line {
            width: 40px;
            margin-right: 10px;
          }
        }
      }

      .right {
        padding: 0px 20px;
      }
    }

    .blogList {
      padding: 0px 16px;
      .header {
        font-size: 32px;
      }

      .middleBlogs {
        .blogCard {
          padding: 15px 15px;
        }
      }
    }

    .news {
      border-radius: 28px;
      border: 1.5px solid #DC633B;
      padding: 30px 16px;
      margin-top: 120px;
      margin: 40px 16px 0px;
      text-align: center;

      h1 {
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 40px */
      }

      p {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 25px 0px;
      }

      .buttonWrapper {
        margin: 0 auto;
        width: auto;
        background: #fff0e5;
        padding: 15px 20px;
        border-radius: 14.5px;
        display: flex;
        justify-content: space-between;
        gap: 15px;

        input {
          color: #606060;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.08px;
          border: none;
          width: 100%;
          outline: none;
          background: transparent;
          // padding: 10px 15px;
        }

        .icon {
          transform: rotate(180deg);
          cursor: pointer;
        }
      }
    }
  }
}
