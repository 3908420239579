.wrapper {
  position: relative;
  margin: 100px 0px 0px;
  padding-bottom: 40px;

  .topSection {
    display: flex;
    gap: 0px;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    .left {
      // width: 40%;
      flex: 0.5;

      .header {
        color: #161616;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 56px */
      }

      .desc {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 24px */
        margin: 50px 0px;
      }

      .buttonCont {
        width: 160px;
      }

      .dark {
        color: white;
      }
    }

    .right {
      flex: 0.454;
      width: 460px;
      position: relative;

      .card {
        padding: 30px 30px 40px;
        border: 2px solid #dc633b;
        background: #fff;
        width: 460px;
        height: 400px;

        .icon {
          // width: 70px;
          height: 70px;
        }

        h3 {
          color: #161616;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 32px */
          margin: 30px 0px 20px;
          // width: 220px;
        }

        p {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 124%;
          /* 22.32px */

          // width: 450px;
        }
      }

      .bottomSec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .right {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: right;
        }

        .left {
          color: #161616;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 24px */
        }

        .dark {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    margin: 80px 0px 0px;

    .topSection {
      display: flex;
      flex-direction: column;
      gap: 26px;
      padding: 0 16px;

      .left {
        text-align: center;

        .header {
          font-size: 32px;
          padding-top: 50px;
        }

        .desc {
          font-size: 24px;
          /* 24px */
          margin: 30px 0px;
        }

        .buttonCont {
          width: 160px;
          margin: 0 auto;
        }
      }

      .right {
        .card {
          padding: 30px 16px;
          border: 2px solid #dc633b;
          width: 300px;
          height: 402px;

          .icon {
            width: 50px;
            height: 50px;
          }

          h3 {
            font-size: 24px;
            margin: 20px 0px;
            width: auto;
          }

          p {
            font-size: 14px;
          }
        }

        .bottomSec {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .right {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .left {
            color: #161616;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
          }
        }
      }
    }
  }
}
