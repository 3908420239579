.wrapper {
    padding-top: 128px;
    display: flex;
    gap: 34px;
    align-items: center;

    .right {
        flex: 0.7;
        h2 {
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            margin-bottom: 20px;
        }

        p {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }
    }

    .contents {
        border-radius: 28px;
        border: 1.5px solid #DC633B;
        padding: 30px 50px 50px 40px;
        flex: 0.275;

        h3 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            margin-bottom: 25px;
        }

        p {
            color: #838383;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 15px;
        }

        .active {
            color: #DC633B;
        }

        hr {
            margin-bottom: 25px;
        }

    }

    @media screen and (max-width: 576px) {
        flex-direction: column;
        padding-top: 30px;

        .right {
            padding: 0px 20px;
            text-align: center;
        }
    }
}
