.wrapper {
  padding-top: 70px;
  margin-bottom: 50px;

  .header {
    color: #161616;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 48px */
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    gap: 105px;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .first {
        color: #DC633B;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 24px */
      }

      .second {
        color: #222;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 106%;
        /* 19.08px */
        margin: 30px 0px;
      }

      .list {
        .items {
          display: flex;
          gap: 16px;
          justify-content: flex-start;
          align-items: center;
          margin: 22px 0px;

          .stars {
            width: 18px;
            height: 18px;
          }

          p {
            color: #4a4a4a;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 18px */
          }
        }
      }
    }

    .right {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      align-items: flex-start;
      justify-content: flex-start;

      .items {
        flex: 1;
        border-radius: 15px;
        border: 1px solid #000;
        background: #fff;
        // height: 204px;
        padding: 50px 22px;
        &.active {
          background: #DC633B;
          border-color: #DC633B;
          p {
            color: #fff;
          }
        }
        &.one {
          min-width: 233px;
          transform: translateY(50px);
          padding: 22px;
        }
        &.two {
          max-height: 204px;
        }
        &.three {
          transform: translateY(25px);
          padding: 36px 22px;
        }

        h2 {
          color: #1e1e1e;
          font-size: 40px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          max-width: 120px;
        }

        p {
          color: #252525;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          /* 24px */
          max-width: 120px;
        }
      }
    }
  }

  .samples {
    h1 {
      color: #161616;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 48px */
    }

    .cardsSection {
      display: flex;
      justify-content: space-between;
      gap: 27px;
      margin-top: 40px;

      .cards {
        border: 1px solid #ababab;
        background: #fff;
        padding: 18px 36px;

        .cardImage {
          width: 270px;
          height: 243px;
        }

        h3 {
          color: #000;
          font-size: 21.6px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 21.6px */
          margin: 31px 0px 13px;
        }

        p {
          color: #DC633B;
          font-size: 14.4px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 14.4px */
        }
      }
    }
  }
}


@media screen and (max-width: 576px) {
  .wrapper {
    padding: 70px 16px 0;

    .header {
      font-size: 32px;
    }

    .content {
      flex-direction: column;
      gap: 50px;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .first {
          color: #DC633B;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          /* 24px */
        }

        .second {
          color: #222;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 106%;
          /* 19.08px */
          margin: 30px 0px;
        }

        .list {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          justify-content: space-around;
          .items {
            display: flex;
            gap: 16px;
            justify-content: flex-start;
            align-items: center;
            margin: 0px;

            .stars {
              width: 18px;
              height: 18px;
            }

            p {
              color: #4a4a4a;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              /* 18px */
            }
          }
        }
      }

      .right {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 50px;

        .items {
          flex: 1;
          border-radius: 10px;
          border: 1px solid #000;
          background: #fff;
          // height: 204px;
          padding: 20px 11px;
          &.active {
            background: #DC633B;
            border-color: #DC633B;
            p {
              color: #fff;
            }
          }
          &.one {
            min-width: 133px;
            transform: translateY(20px);
            padding: 22px;
          }
          &.two {
            max-height: 104px;
          }
          &.three {
            transform: translateY(20px);
            padding: 20px 11px;
          }

          h2 {
            color: #1e1e1e;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            max-width: 120px;
          }

          p {
            color: #252525;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 24px */
            max-width: 120px;
          }
        }
      }
    }

    .samples {
      h1 {
        color: #161616;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 48px */
      }

      .cardsSection {
        display: flex;
        justify-content: center;
        gap: 27px;
        margin-top: 40px;

        .cards {
          border: 1px solid #ababab;
          background: #fff;
          padding: 18px 16px;

          .cardImage {
            width: 270px;
            height: 243px;
          }

          h3 {
            color: #000;
            font-size: 21.6px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 21.6px */
            margin: 31px 0px 13px;
          }

          p {
            color: #DC633B;
            font-size: 14.4px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14.4px */
          }
        }
      }
    }
  }
}
