
    .wrapperCont {
        padding: 0px 200px;
        h2 {
            text-align: center;
            color: #DC633B;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            padding-top: 67px;
            padding-bottom: 30px;
        }

        .mainCont {
            padding-bottom: 70px;

            .left {
                flex: 0.5;

                p {
                    text-align: center;
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }

                .steps {
                    padding: 20px 10px;
                    border-radius: 20px;
                    background: #DC633B;
                    margin-top: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .items {
                        flex: 0.3;
                        padding: 0px 12px;

                        h3 {
                            color: #fff;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            margin-bottom: 9px;
                            text-align: left;
                        }

                        p {
                            color: #fff;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            text-align: left;
                        }
                    }

                    .bordered {
                        border-right: 0.5px solid #fff;
                        border-left: 0.5px solid #fff;
                    }
                }
            }

            .right {
                height: 415px;
                width: 100%;
                flex: 0.5;
                border-radius: 20px;
                background: #D9D9D9;
            }

        }

        @media screen and (max-width: 576px) {
            padding: 0px 20px;
        }
    }
