.wrapper {
    position: relative;

    .bgColor {
        background: #000;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
    }

    .contentWrapper {

        .partners {
            display: flex;
            justify-content: space-between;
            padding: 22.5px 0;
            margin-bottom: 100px;

            .logoImage {
                width: 145px;
                height: 35px;
            }
        }

        h1 {
            color: #2C2C2C;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 48px */
        }

        .textBody {
            display: flex;
            gap: 100px;
            margin-top: 40px;
            margin-bottom: 80px;

            color: #000;
            text-align: justify;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            .left {
                flex: 3;
            }

            .right {
                flex: 3;
            }
        }

        .complement {
            color: #000;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%;
            /* 32px */
            margin-bottom: 40px;
        }

        .cardsSection {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;

            .cards {
                border-radius: 20px;
                border: 1px solid #000;
                background: #fff;
                padding: 40px 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .icon {
                    width: 36px;
                    height: 36px;
                }

                h2 {
                    color: #000;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

    .dominate {
        padding: 80px 0px;
        background: #131313;

        .headerSection {
            h1 {
                color: #FFF;
                text-align: center;
                font-size: 48px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 48px */
            }

            h3 {
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.5%;
                /* 22.9px */
                margin-top: 20px;
                max-width: 706px;
                margin: 20px auto 40px;
            }
        }

        .cardsSection {
            display: flex;
            justify-content: space-between;
            gap: 17.5px;

            .cards {
                height: 350px;
                border-radius: 17.5px;
                padding: 17px 26px;
                background: #FFF;
                text-align: center;
                flex: 1;
                position: relative;

                h2 {
                    color: #000;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 21.88px;
                }

                .bottomImage {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding: 0px 0px;

        .bgColor {
            height: 220px;
        }

        .contentWrapper {
            padding: 0 16px;

            .partners {
                margin-bottom: 50px;
                flex-wrap: wrap;
                gap: 20px;

                .logoImage {
                    height: 40px;
                    width: 100px;
                }
            }

            h1 {
                font-size: 24px;
                /* 48px */
            }

            .textBody {
                flex-direction: column;
                gap: 0px;
                margin-top: 25px;
                margin-bottom: 50px;
                font-size: 16px;

                .left {
                    flex: 3;
                }

                .right {
                    flex: 3;
                }
            }

            .complement {
                font-size: 24px;
            }

            .cardsSection {

            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            .cards {
                h2 {
                    font-size: 18px;
                }
            }
            }
        }

        .dominate {
            padding: 40px 16px 33px;

            .headerSection {
                h1 {
                    font-size: 24px;
                    /* 48px */
                }

                h3 {
                    font-size: 16px;
                    margin: 20px auto 30px;
                }
            }

            .cardsSection {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                gap: 17.5px;

                .cards {
                    height: 150px;
                    padding: 15px 21px;
                    text-align: left;
                    align-items: flex-start;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;

                    .logoImage {
                        width: 40px;
                        height: 40px;
                    }

                    h2 {
                        font-size: 18px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
