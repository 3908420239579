.sureSection {
    // margin-top: 75px;

    .headerSection {

        h1 {
            color: #ffffff;
            text-align: center;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            padding-bottom: 30px;
            /* 56px */
            /* 48px */
        }

        .buttonCont {
            max-width: fit-content;
            margin: 30px auto;
        }

        p {
            color: #161616;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 118%;
            /* 28.32px */
            /* 22.9px */
            /* 28.32px */
            max-width: 838px;
            margin: 0 auto;
        }
    }

    .cardsSection {
        display: flex;
        gap: 20px;
        margin-top: 20px;

        .cards {
            border-radius: 15.412px;
            border: 1px solid #000;
            background: #FFF;
            padding: 20px 0px 20px 20px;
            flex: 33%;

            .logoImage {
                width: 50px;
                height: 50px;
            }

            hr {
                opacity: 0.5;
            }

            h2 {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                /* 24px */
                margin: 15px 0px;

            }

            p {
                color: #000;
                text-align: left;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                /* 14px */

            }

            .buttonCont {
                border-radius: 27px;
                border: 0.5px solid #000;
                padding: 10px 0px;
                width: 121px;
                text-align: center;
                color: #000;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 15px;
                cursor: pointer;
            }

            hr {
                margin: 15px 0px;
                width: 150px;
            }

            .highlight {
                color: #DC633B;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            ul {
                margin-left: 20px;

                li {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .sureSection {
        padding: 0 16px;

        .headerSection {
            h1 {
                font-size: 32px;
            }

            p {
                font-size: 14px;
            }
        }

        .cardsSection {
            flex-direction: column;
        }
    }
}
