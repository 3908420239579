.collabBanner {
    border-radius: 15px;
    background: #DC633B;
    width: 903px;
    padding: 44px 90px 50px;
    margin: auto;
    position: relative;
    margin-top: 50px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .rocket {
        position: absolute;
        left: 20px;
        top: -20px;
    }

    .pattern {
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom-right-radius: 15px;
    }

    h2 {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.42px;
    }

    .button {
        display: inline-flex;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        z-index: 2;
        cursor: pointer;

        p {
            color: #363636;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
        }

        .arrow {
            width: 24px;
            height: 24px;

            transform: rotate(180deg);
        }
    }

    @media screen and (max-width: 576px) {
        border-radius: 15px;
            width: auto;
            padding: 57px 24px 97px;
            margin: auto 16px;
            flex-direction: column;
            gap: 10px;
            margin-top: 70px;
            margin-bottom: 40px;

            .rocket {
              position: absolute;
              left: 20px;
              top: -20px;
            }

            .pattern {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 327px;
              border-bottom-right-radius: 15px;
              border-bottom-left-radius: 15px;
            }

            h2 {
              font-size: 24px;
              text-align: center;
            }
    }
}
