.wrapper {
  padding: 50px 0;
  h4 {
    color: #161616;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 56px */
    margin-bottom: 50px;
  }
  p {
    color: #161616;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 118%; /* 23.6px */
    margin-bottom: 22px;
  }
  .pricingList {
    display: flex;
    justify-content: center;
    gap: 20px;
    .card {
      padding: 40px 20px;
      border-radius: 15.412px;
      border: 1px solid #000;
      background: #fff;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 15px;
      max-width: 350px;
      .price {
        color: #f87a23;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 128%; /* 61.44px */
        span {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 128%; /* 30.72px */
        }
      }
      .desc {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .descTwo {
        color: #DC633B;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      ul {
        padding-left: 15px;
        li {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      button {
        display: block;
        margin-right: auto;
        padding: 10px 15px;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: none;
        border-radius: 27px;
        border: 1px solid #000;
      }
      .cta{
        margin-top: auto;

      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 50px 16px;
    h4 {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }

    .pricingList {
      flex-direction: column;

      .card {
        .price {
          font-size: 24px;
        }

        .desc {
          font-size: 18px;
        }

        ul {
          li {
            font-size: 14px;
          }
        }
      }
    }
  }
}
