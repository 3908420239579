.wrapper {
    padding-top: 128px;
    text-align: center;
    p {
        color: #DC633B;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // letter-spacing: -0.3px;
    }

    h1 {
        color: #2e2e2e;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.3px;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .image {
        width: 100%;
        height: 480px;
        background: #D9D9D9;
        border-radius: 20px;
    }

    @media screen and (max-width: 576px) {
        padding: 128px 20px 0px;
    }
}
