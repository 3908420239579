.wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 85vh;
  align-items: center;
  gap: 130px;
  padding-top: 50px;

  .left {
    // flex: 1;

    .top {
      color: #DC633B;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 19px;
    }

    .header {
      color: #000;
      font-size: 56px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 19px;
      .highlighted {
        color: #DC633B;
      }
    }
    .subtitle {
      color: #494949;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
        line-height: normal;
    }

    .buttonWrapper {
      display: flex;
      gap: 18px;
      margin-top: 40px;
    }
  }

  .right {
    position: relative;

    .heroIllus {
      // flex: 1;
      width: 383px;
      height: 412px;

      &.mobile {
        width: 328px;
        height: 300px;
      }
    }

    .nextArrow {
      cursor: pointer;
      position: absolute;
      bottom: -30px;
      right: 60px;
      z-index: 2;
    }

    .prevArrow {
      cursor: pointer;
      position: absolute;
      bottom: -30px;
      right: 0;
      transform: rotate(180deg);
      z-index: 2;
    }

    .whoWeAre {
      position: absolute;
      bottom: -30px;
      left: 0;
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    flex-direction: column;
    min-height: 85vh;
    gap: 30px;
    padding-top: 120px;
    padding-left: 16px;
    padding-right: 16px;

    .left {
      // flex: 1;

      .top {
        color: #DC633B;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 19px;
      }

      .header {
        color: #000;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 19px;
        .highlighted {
          color: #DC633B;
        }
      }

      .subtitle {
        font-size: 18px;
      }


    }

    .buttonWrapper {
      display: flex;
      gap: 18px;
      margin: 20px 0 40px;
    }

    .right {
      position: relative;

      .heroIllus {
        // flex: 1;
        width: 322px;
        height: 344px;

      }

      .nextArrow {
        cursor: pointer;
        position: absolute;
        bottom: -30px;
        right: 60px;
        z-index: 2;
      }

      .prevArrow {
        cursor: pointer;
        position: absolute;
        bottom: -30px;
        right: 0;
        transform: rotate(180deg);
        z-index: 2;
      }

      .whoWeAre {
        position: absolute;
        bottom: -30px;
        left: 0;
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
