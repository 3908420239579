.wrapper {

    .topSection {
        margin: 100px 0px 110px;

        h1 {
            color: #2C2C2C;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 48px */

            &.highlighted {
                color: #DC633B;
            }
        }

        .content {
            display: flex;
            gap: 116px;
            margin-top: 40px;

            .left {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 24px */
            }

            .right {
                .banner {
                    width: 508px;
                    height: 339px;
                }
            }
        }
    }

    .secondSection {
        padding-top: 80px;
        padding-bottom: 72px;
        background: #DC633B;

        .headerSection {
            margin-bottom: 60px;

            h1 {
                color: #353535;
                text-align: center;
                font-size: 56px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 56px */
            }

            p {
                color: #262626;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                // line-height: 0%; /* 0px */
                margin: 40px auto 0px;
                width: 706px;
            }
        }

        .cardsSection {
            display: flex;
            gap: 30px;

            .cards {
                flex: 1;
                height: 400px;
                border-radius: 20px;
                background: #FFF;
                padding: 20px;

                h1 {
                    color: #000;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128%;
                    /* 23.04px */
                    margin-top: 25px;
                    max-width: 240px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {

        .topSection {
            margin: 43px 0px 50px;

            h1 {
                font-size: 24px;
            }

            .content {
                display: flex;
                flex-direction: column-reverse;
                gap: 30px;
                margin-top: 30px;
                align-items: center;
                padding: 0 16px;

                .left {
                    font-size: 16px;
                    /* 24px */
                }

                .right {
                    .banner {
                        width: 327px;
                        height: 218px;
                    }
                }
            }
        }

        .secondSection {
            padding-top: 60px;
            padding-bottom: 60px;
            padding: 60px 16px;

            .headerSection {
                margin-bottom: 40px;

                h1 {
                    font-size: 32px;
                }

                p {
                    font-size: 16px;
                    margin: 40px auto 0px;
                    width: auto;
                }
            }

            .cardsSection {
                display: flex;
                gap: 30px;

                .cards {
                    height: 350px;
                    border-radius: 17.45px;
                    background: #FFF;
                    padding: 17.45px;

                    h1 {
                        text-align: center;
                        font-size: 27.9px;
                    }

                    p {
                        font-size: 15.7px;
                        text-align: center;
                        /* 23.04px */
                        margin: 0 auto;
                        margin-top: 21px;
                        max-width: 209px;
                    }
                }
            }
        }
    }
}
