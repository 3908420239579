.wrapper {

    position: relative;
    height: 80vh;

    .leftAbs {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 413px;
        height: 347px;
    }

    .middleAbs {
        position: absolute;
        left: 35%;
        bottom: 0;
        width: 397px;
        height: 249px;
    }

    .rightAbs {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 458px;
        height: 332px;
    }

    .contentWrapper {
        padding: 140px 213px 330px 250px;
        

        .headerText {
            color: #000;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.5%;
            /* 54.96px */
            /* 56px */
            max-width: 780px;
            margin: 0 auto 20px;

        }

        p {
            color: #494949;
            text-align: center;
            font-family: Satoshi;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;

            max-width: 780px;
            margin: 0 auto;
        }

        .buttonContainer {
            width: 155px;
            margin: 50px auto 0;
        }

        .highlight {
            color: #FFF;
        }

        .blocksWrapper {
            display: flex;
            gap: 0;

            .blocks {
                flex: 1;

                h3 {
                    color: #FFF;
                    text-align: center;
                    font-size: 56px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 56px */

                }

                p {
                    color: #FFF;
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    /* 24px */
                    margin-top: 36px;
                }
            }

            .nonZero {
                border-left: 1px solid #fff;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        .leftAbs {
            width: 138px;
            height: 116px;
        }

        .rightAbs {
            width: 153px;
            height: 111px;
        }

        .contentWrapper {
            padding: 0px;
            padding-top: 220px;
            .headerText {
                font-size: 28px;
            }

            p {
                font-size: 18px;
                padding: 0px 16px;
            }
        }
    }
}