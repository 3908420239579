.appointment {
    border-radius: 19px;
    border: 1.5px solid #000;
    padding: 40px 117px;
    background: #fff;
    display: flex;
    gap: 30px;

    .left {
      text-align: left;

      .topText {
        color: #DC633B;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .header {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 20px 0px 40px;
        white-space: pre-line;
      }

      .buttonCont {
        width: 226px;
      }
    }

    .right {
      margin: auto 0;

      .logoImage {
        width: 159px;
        height: 165.5px;
      }
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      margin: 0 16px;
      padding: 25px 20px;
      .left {
        text-align: center;

        .header {
          font-size: 24px;
          margin: 20px 0px 40px;
        }

        .buttonCont {
          width: 226px;
          margin: auto;
        }
      }

      .right {
        margin: auto ;

        .logoImage {
          width: 159px;
          height: 165.5px;
        }
      }
    }
  }
