.wrapper {
  position: relative;

  .topSection {
    .headerSection {
      margin-top: 100px;

      h1 {
        color: #000;
        text-align: center;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 56px */
        margin-bottom: 40px;
      }

      .buttonWrapper {
        display: flex;
        gap: 30px;
        justify-content: center;

        .button {
          border-radius: 37px;
          border: 1.5px solid #DC633B;
          padding: 10px 20px 10px 30px;
          display: flex;
          justify-content: space-between;
          width: 335px;
          cursor: pointer;

          p {
            color: #9e9e9e;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 124.5%;
            /* 24.9px */
            letter-spacing: -0.4px;
          }

          .arrow {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .topBlogs {
      display: flex;
      gap: 45px;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 100px;

      .left {
        flex: 1;
        cursor: pointer;

        .heroImage {
          width: 480px;
          height: 261px;
          object-fit: cover;
        }

        h1 {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          text-align: left;
          margin-top: 20px;
        }

        h3 {
          color: #DC633B;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          /* 24px */
          margin: 15px 0;
        }

        p {
          color: #434343;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 114.5%;
          /* 18.32px */
        }

        .author {
          color: #3a3a3a;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 16px */
          margin: 8px 0;
        }

        .date {
          color: #6d6d6d;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 12px */
        }
      }

      .right {
        flex: 1;

        .blogCard {
          display: flex;
          gap: 16px;
          align-items: center;
          margin-bottom: 30px;
          cursor: pointer;

          .heroImage {
            width: 152px;
            height: 149px;
            object-fit: cover;
          }

          .details {
            .title {
              color: #000;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              /* 24px */
            }

            .type {
              color: #DC633B;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              /* 16px */
              margin: 15px 0 7px;
            }

            .author {
              color: #3a3a3a;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 16px */
              margin-bottom: 8px;
            }

            .date {
              color: #6d6d6d;
              font-family: Satoshi;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 12px */
            }
          }
        }
      }
    }
  }

  .middleBlogs {
    // display: flex;
    // gap: 61px;
    // flex-wrap: nowrap;
    // padding-left: 61px;
    // width: 1200px;
    padding-bottom: 100px;

    .blogCard {
      // margin-bottom: 30px;
      // margin-right: 15px;
      margin-left: 30px;
      cursor: pointer;

      .heroImage {
        width: 275px;
        height: 270px;
        object-fit: cover;
      }

      .details {
        width: 275px;
        padding-top: 30px;
        .title {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          overflow-wrap: break-word;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;

          /* 24px */
        }

        .type {
          color: #DC633B;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          /* 16px */
          margin: 15px 0 7px;
        }

        .author {
          color: #3a3a3a;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 16px */
          margin-bottom: 8px;
        }

        .date {
          color: #6d6d6d;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 12px */
        }
      }
    }
  }

  .directors {
    position: relative;
    padding: 138px 0;
    // overflow: hidden;

    .heroImage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .header {
      position: relative;
      text-align: center;
      margin: 0 auto 50px;
      max-width: 620px;

      h1 {
        color: #000;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        width: 100%;
        /* 56px */
      }

      .desc {
        margin-top: 20px;

        p {
          color: #393939;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 124.5%;
          /* 29.88px */
          letter-spacing: -0.48px;
          text-align: left;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            height: 1px;
            left: 130px;
            right: 0;
            background: #000;
            transform: translateY(-50%);
          }
        }
      }
    }

    .blogCards {
      position: relative;
      margin-top: 45px;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      .sliderContainer {
        width: 700px;
        position: relative;
        max-height: 525px;
        @media screen and (max-width: 576px) {
          width: 100%;
        }
        .prevArrow {
          transform: rotate(180deg);
          position: absolute;
          bottom: 400px;
          right: 100px;
        }
        .nextArrow {
          position: absolute;
          bottom: 400px;
          right: 20px;
        }
        .slick-track {
          display: flex;
        }
      }

      .blogCard {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        cursor: pointer;
        padding: 18.976px 28.464px;
        background: #f6f6f6;
        cursor: pointer;
        margin-right: 20px;

        .heroImage {
          width: 275px;
          height: 270px;
          position: relative;
          margin-bottom: 20px;
          object-fit: cover;
        }

        .details {
          .title {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 24px */
          }

          .type {
            color: #DC633B;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
            margin: 15px 0 7px;
          }

          .author {
            color: #3a3a3a;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 16px */
            margin-bottom: 8px;
          }

          .date {
            color: #6d6d6d;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 12px */
          }
        }
      }
    }
  }

  .mainList {
    display: flex;
    flex-wrap: wrap;
    gap: 66px;
    padding-top: 100px;
    justify-content: center;

    .blogCard {
      display: flex;
      flex-direction: column;
      gap: 35px;
      align-self: flex-start;
      // align-items: center;
      margin-bottom: 30px;
      padding: 18.976px 28.464px;
      background: #f6f6f6;
      cursor: pointer;
      width: 203px;

      .heroImage {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      .details {
        .title {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 75px;
          /* 24px */
        }

        .type {
          color: #DC633B;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          /* 16px */
          margin: 15px 0 7px;
        }

        .author {
          color: #3a3a3a;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 16px */
          margin-bottom: 8px;
        }

        .date {
          color: #6d6d6d;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 12px */
        }
      }
    }
  }

  .news {
    border-radius: 28px;
    border: 1.5px solid #DC633B;
    padding: 50px 290px;
    margin-top: 120px;
    text-align: center;

    h1 {
      color: #000;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 40px */
    }

    p {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 25px 0px;
    }

    .buttonWrapper {
      margin: 0 auto;
      width: 457px;
      background: #fff0e5;
      padding: 15px 20px;
      border-radius: 14.5px;
      display: flex;
      justify-content: space-between;
      gap: 15px;

      input {
        color: #606060;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.08px;
        border: none;
        width: 100%;
        outline: none;
        background: transparent;
        // padding: 10px 15px;
      }

      .icon {
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    .topSection {
      .headerSection {
        h1 {
          font-size: 32px;
        }

        .buttonWrapper {
          padding: 0px 16px;
          .button {
            padding: 10px 10px;
            align-items: center;
            gap: 2px;
            p {
              font-size: 12px;
            }
          }
        }
      }

      .topBlogs {
        flex-direction: column;
        padding: 0px 16px;

        .left {
          .heroImage {
            width: 325px;
            height: 260px;
          }
        }

        .right {
        }
      }
    }
    .middleBlogs {
      padding-left: 45px;
    }

    .directors {
      padding: 80px 16px;
      .heroImage {
        height: 730px;
      }

      .header {
        h1 {
          font-size: 32px;
        }

        .desc {
          p {
            font-size: 20px;
          }
        }
      }
    }

    .news {
      border-radius: 28px;
      border: 1.5px solid #DC633B;
      padding: 30px 16px;
      margin-top: 120px;
      margin: 80px 16px 0px;
      text-align: center;

      h1 {
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 40px */
      }

      p {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 25px 0px;
      }

      .buttonWrapper {
        margin: 0 auto;
        width: auto;
        background: #fff0e5;
        padding: 15px 20px;
        border-radius: 14.5px;
        display: flex;
        justify-content: space-between;
        gap: 15px;

        input {
          color: #606060;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.08px;
          border: none;
          width: 100%;
          outline: none;
          background: transparent;
          // padding: 10px 15px;
        }

        .icon {
          transform: rotate(180deg);
          cursor: pointer;
        }
      }
    }
  }
}
