.wrapper {

    .content {
        .partners {
            display: flex;
            justify-content: space-between;
            padding: 22.5px 0;
            margin-bottom: 100px;

            .logoImage {
                width: 145px;
                height: 35px;
            }
        }

        .buttonWrapper {
            display: flex;
            gap: 30px;
            justify-content: center;

            .button {
                border-radius: 37px;
                border: 1.5px solid #DC633B;
                padding: 10px 20px 10px 30px;
                display: flex;
                justify-content: space-between;
                width: 335px;
                cursor: pointer;
                position: relative;

                p {
                    color: #9E9E9E;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 124.5%;
                    /* 24.9px */
                    letter-spacing: -0.4px;
                }

                .arrow {
                    width: 24px;
                    height: 24px;
                }

                .dropdown {
                    position: absolute;
                    top: 60px;
                    right: 0;
                    left: 0;
                    border: 1.5px solid #DC633B;
                    padding: 20px;
                    border-radius: 25px;
                    background: white;

                    .item {

                    }

                    hr {
                        margin: 15px 0;
                    }
                }
            }
        }

        .cardContainer {
            display: flex;
            gap: 33px;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 40px;
            margin-bottom: 60px;

            .cards {
                max-width: 330px;
                border: 1px solid #000;

                .banner {
                    width: 330px;
                    height: 234px;
                }

                .textWrapper {
                    padding: 15px 21px 40px;

                    .header {
                        color: #DC633B;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 114.5%;
                        /* 18.32px */
                        margin-bottom: 21px;
                    }

                    .middle {
                        color: #43A716;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 114.5%;
                        /* 18.32px */
                    }

                    .desc {
                        color: #2E2E2E;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 111.5%;
                        /* 17.84px */
                    }
                }
            }
        }

        .news {
            border-radius: 28px;
            border: 1.5px solid #DC633B;
            padding: 50px 290px;
            margin-bottom: 60px;
            text-align: center;

            h1 {
                color: #000;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 40px */
            }

            p {
                color: #000;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 25px 0px;
            }

            .buttonWrapper {
                margin: 0 auto;
                width: 457px;
                background: #FFF0E5;
                padding: 15px 20px;
                border-radius: 14.5px;
                display: flex;
                justify-content: space-between;
                gap: 15px;

                input {
                    color: #606060;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.08px;
                    border: none;
                    width: 100%;
                    outline: none;
                    background: transparent;
                    // padding: 10px 15px;
                }

                .icon {
                    transform: rotate(180deg);
                    cursor: pointer;
                }
            }

        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        .content {
            .partners {
                padding: 10px 0px;
                margin-bottom: 50px;
                .logoImage {
                    width: 90px;
                    height: 60px;
                    padding: 0 15px;
                }
            }

            .buttonWrapper {
                padding: 0px 16px;

                .button {
                    padding: 5px 10px;
                    align-items: center;

                    p {
                        font-size: 12px;
                    }
                }
            }

            .news {
                border-radius: 28px;
                border: 1.5px solid #DC633B;
                padding: 30px 16px;
                margin-top: 120px;
                margin: 80px 16px 0px;
                text-align: center;

                h1 {
                    color: #000;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    /* 40px */
                }

                p {
                    color: #000;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin: 25px 0px;
                }

                .buttonWrapper {
                    margin: 0 auto;
                    width: auto;
                    background: #FFF0E5;
                    padding: 15px 20px;
                    border-radius: 14.5px;
                    display: flex;
                    justify-content: space-between;
                    gap: 15px;

                    input {
                        color: #606060;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 1.08px;
                        border: none;
                        width: 100%;
                        outline: none;
                        background: transparent;
                        // padding: 10px 15px;
                    }

                    .icon {
                        transform: rotate(180deg);
                        cursor: pointer;
                    }
                }

            }
        }
    }
}
