.wrapper {
    .headerSection {
        margin-top: 68.5px;
        text-align: center;

        h1 {
            color: #2C2C2C;
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 4px;
        }

        p {
            color: #2C2C2C;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .cardsSection {
        position: relative;
        // width: 900;
        margin-top: 32px;
        .nextArrow {
            cursor: pointer;
            position: absolute;
            bottom: -65px;
            right: 102px;
            transform: rotate(0deg);
            z-index: 2;
        }

        .prevArrow {
            cursor: pointer;
            position: absolute;
            bottom: -65px;
            right: 0;
            z-index: 2;

            transform: rotate(180deg);
            // top: -20px;
        }

        .button__bar {
            display: inline-block;
            vertical-align: middle;
            margin: auto;
            padding: 0;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 40px;
            text-align: center;
            position: absolute;
            bottom: -65px;
            right: 35px;
            z-index: 5;
          }

          .button__bar li {
            list-style: none;
            cursor: pointer;
            display: inline-block;
            margin: 0 3px;
            padding: 0;
          }

          .button__bar li button {
            border: none;
            background: #FFBC8D;;
            color: transparent;
            cursor: pointer;
            display: block;
            height: 8px;
            width: 8px;
            border-radius: 7.5px;
          }

          .button__bar li.slick-active button {
            background-color: rgb(8, 255, 140);
          }

          .button__bar li button:hover,
          .button__bar li button:focus {
            background: rgba(247, 34, 34, 0.8);
            outline: 0;
          }

        // display: flex;
        .cards {
            display: flex;
            // flex-direction: row;
            border-radius: 15px;
            border: 1px solid #DC633B;
            margin: 0 auto;
            width: calc(100% - 25px);
            overflow: hidden;

            .cardImage {
                width: 188px;
                height: 236px;
            }

            .content {
                padding: 23px 44px 23px 22px;

                .top {
                    color: #DC633B;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .middle {
                    color: #2C2C2C;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    margin-top: 12px;
                    margin-bottom: 54px;
                }

                .buttonContTwo {
                    border-radius: 9px;
                    background: #DC633B;
                    display: flex;
                    padding: 8px 20px;
                    justify-content: center;
                    align-items: center;
                    max-width: 80px;

                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

    .buttonCont {
        max-width: 300px;
        margin: 32px auto 0px;
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        .headerSection {
            padding: 0px 16px;
            h1 {
                font-size: 32px;
            }

            p {
                font-size: 14px;
            }
        }

        .cardsSection {
            .cards {
                display: flex;
                flex-direction: row;
                border-radius: 15px;
                border: 1px solid #DC633B;
                margin: 0 auto;
                width: calc(100% - 25px);
                overflow: hidden;

                .cardImage {
                    width: 168px;
                    height: 220px;
                }

                .content {
                    padding: 10px 10px;

                    .top {
                        color: #DC633B;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .middle {
                        color: #2C2C2C;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        margin-top: 12px;
                        margin-bottom: 54px;
                    }

                    .buttonContTwo {
                        border-radius: 9px;
                        background: #DC633B;
                        display: flex;
                        padding: 8px 20px;
                        justify-content: center;
                        align-items: center;
                        max-width: 80px;

                        color: #FFF;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
