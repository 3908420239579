.wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 85vh;
  align-items: center;
  gap: 50px;
  padding-top: 30px;

  .left {
    flex: 1;
      opacity: 1;

    h1 {
      color: #000;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      text-align: left;
    }

    p {
      color: #151515;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: left;
    }

    .highlighted {
      color: #DC633B;
    }

    .buttonWrapper {
      display: flex;
      gap: 18px;
    }
  }

  .right {
    width: 460px;
    position: relative;

    .button__bar {
      display: inline-block;
      vertical-align: middle;
      margin: auto;
      padding: 0;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 40px;
      text-align: center;
      position: absolute;
      bottom: -32px;
      right: 30px;
      z-index: 5;
    }

    .button__bar li {
      list-style: none;
      cursor: pointer;
      display: inline-block;
      margin: 0 3px;
      padding: 0;
    }

    .button__bar li button {
      border: none;
      background: #ffbc8d;
      color: transparent;
      cursor: pointer;
      display: block;
      height: 8px;
      width: 8px;
      border-radius: 7.5px;
    }

    .button__bar li.slick-active button {
      background-color: rgb(8, 255, 140);
    }

    .button__bar li button:hover,
    .button__bar li button:focus {
      background: rgba(247, 34, 34, 0.8);
      outline: 0;
    }

    .heroIllus {
      width: 460px;
      height: 430px;

      &.mobile {
        width: 328px;
        height: 300px;
      }
    }

    .nextArrow {
      cursor: pointer;
      position: absolute;
      bottom: -30px;
      right: 78px;
      z-index: 2;
    }

    .prevArrow {
      cursor: pointer;
      position: absolute;
      bottom: -30px;
      right: 0;
      transform: rotate(180deg);
      z-index: 2;
    }

    .whoWeAre {
      position: absolute;
      bottom: -30px;
      left: 0;
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    flex-direction: column;
    min-height: 100vh;
    padding: 120px 16px 0px;
    gap: 0px;

    .left {
      flex: 5;

      h1 {
        color: #000;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 36px */
      }

      p {
        color: #151515;

        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 40px;
        margin-bottom: 30px;
      }

      .buttonWrapper {
        justify-content: center;
      }
    }

    .right {
      width: 328px;
      position: relative;
      flex: 5;
      margin-top: 30px;

      .nextArrow {
        cursor: pointer;
        position: absolute;
        bottom: -30px;
        right: 78px;
        z-index: 2;
      }

      .prevArrow {
        cursor: pointer;
        position: absolute;
        bottom: -30px;
        right: 0;
        transform: rotate(180deg);
        z-index: 2;
      }

      .whoWeAre {
        bottom: 55px;
      }
    }
  }
}
