.wrapper {
    padding-top: 150px;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 100px;

    h1 {
        color: #000;
        text-align: center;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 72px */
    }

    p {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 24px */
        margin-top: 50px;
        padding: 0 85px;
    }

    .italicText {
        color: #000;
        font-size: 20.741px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin-top: 6px;
    }

    .vector {
        margin-top: 20px;
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding-top: 136px;
        max-width: 330px;
        padding-bottom: 100px;
    
        h1 {
            font-size: 36px;
        }
    
        p {
            font-size: 16px;
            /* 24px */
            margin-top: 40px;
            padding: 0px;
        }
    
        .italicText {
            margin-top: 66px;
        }
    }
}