.wrapper {
    padding-top: 130px;
    padding-bottom: 80px;

    .breadcrumb {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;

        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */

            &.light {
                color: #7A7A7A;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 16px */
            }
        }

        .icon {
            width: 5px;
            height: 10px;

        }
    }

    .heroSection {
        display: flex;

        .icon {}

        .middle {
            padding-left: 48px;

            .top {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 16px */
            }

            h1 {
                color: #000;
                font-size: 150px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 150px */
                letter-spacing: 3px;
                margin: 20px 0px 50px;
                text-align: left;
            }

            p {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 20px */
                letter-spacing: 0.4px;
                padding-right: 50px;
            }
        }

        .last {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            p {
                color: #DC7025;
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                line-height: 100%;
                /* 16px */
                letter-spacing: 0.32px;
            }
        }

    }

    .details {
        padding-top: 150px;
        display: flex;
        gap: 50px;
        justify-content: center;

        .left {
            max-width: 200px;
            color: #DC7025;
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
            letter-spacing: 1.44px;
        }

        .right {
            max-width: 550px;

            p {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%;
                /* 22px */
                letter-spacing: 0.4px;
            }
        }
    }

    .bottomSection {
        display: flex;
        gap: 20px;
        justify-content: center;
        padding: 50px 0 100px;

        .left {
            display: flex;
            gap: 20px;

            .iconWrapper {
                display: flex;
                gap: 20px;

                .icon {
                    width: 30px;
                    height: 30px;
                }
            }

            .line {
                width: 271px;
                height: 1px;
                background: #565656;
                margin-top: 15px;
            }
        }



        p {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 124.5%;
            /* 19.92px */
            letter-spacing: -0.32px;
            max-width: 320px;
        }
    }

    .collabBanner {
        border-radius: 15px;
        background: #DC633B;
        width: 903px;
        padding: 44px 90px 50px;
        margin: auto;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .rocket {
            position: absolute;
            left: 20px;
            top: -20px;
        }

        .pattern {
            position: absolute;
            right: 0;
            bottom: 0;
            border-bottom-right-radius: 15px;
        }

        h2 {
            color: #FFF;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.42px;
        }

        .button {
            display: inline-flex;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
            z-index: 2;
            cursor: pointer;

            p {
                color: #363636;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;
            }

            .arrow {
                width: 24px;
                height: 24px;

                transform: rotate(180deg);
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding-top: 100px;
        padding-bottom: 80px;

        .breadcrumb {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;

            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 16px */

                &.light {
                    color: #7A7A7A;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    /* 16px */
                }
            }

            .icon {
                width: 5px;
                height: 10px;

            }
        }

        .heroSection {
            display: flex;
            flex-direction: column;

            .icon {}

            .middle {
                padding: 0px 16px;

                .top {
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    padding-top: 50px;
                    /* 16px */
                }

                h1 {
                    color: #000;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    /* 150px */
                    letter-spacing: 3px;
                    margin: 20px 0px 50px;
                }

                p {
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    /* 20px */
                    letter-spacing: 0.4px;
                    padding-right: 0px;
                }
            }

            .last {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 30px 16px 0px;

                p {
                    color: #DC7025;
                    font-size: 16px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 100%;
                    /* 16px */
                    letter-spacing: 0.32px;
                }
            }

        }

        .details {
            padding: 50px 16px 0px;
            display: flex;
            gap: 50px;
            justify-content: center;
            flex-direction: column;

            .left {
                max-width: 100%;
                color: #DC7025;
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                line-height: 100%;
                /* 16px */
                letter-spacing: 1.44px;
            }

            .right {
                max-width: 550px;

                p {
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%;
                    /* 22px */
                    letter-spacing: 0.4px;
                }
            }
        }

        .bottomSection {
            display: flex;
            gap: 20px;
            justify-content: center;
            padding: 50px 0 100px;

            .left {
                display: flex;
                gap: 20px;

                .iconWrapper {
                    display: flex;
                    gap: 20px;

                    .icon {
                        width: 30px;
                        height: 30px;
                    }
                }

                .line {
                    width: 60px;
                    height: 1px;
                    background: #565656;
                    margin-top: 15px;
                }
            }



            p {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 124.5%;
                /* 19.92px */
                letter-spacing: -0.32px;
                max-width: 320px;
            }
        }

        .collabBanner {
            border-radius: 15px;
            width: auto;
            padding: 57px 24px 97px;
            margin: auto 16px;
            flex-direction: column;
            gap: 10px;
            margin-top: 70px;
            margin-bottom: 40px;

            .rocket {
              position: absolute;
              left: 20px;
              top: -20px;
            }

            .pattern {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 327px;
              border-bottom-right-radius: 15px;
              border-bottom-left-radius: 15px;
            }

            h2 {
              font-size: 24px;
              text-align: center;
            }
          }
    }
}
