.wrapper {

    .growth {
        color: #000;
        text-align: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 20px;
    }

    .bridge {
        width: 100%;

    }

    .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subHeader {
            color: #000;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            margin-top: 80px;
            margin-bottom: 50px;

            .highlight {
                color: #DC633B;
            }
        }

        .rocket {
            margin: 30px 0 80px;
        }

        .headerAndButton {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 100px;

            p {
                color: #DC633B;
                text-align: left;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                flex: 0.7;
            }
        }
    }


.multiImage {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 150px;

    img {
        height: 300px;
    }

    .one {
        padding-top: 140px;
    }

    .two {
        padding-top: 60px;
    }
}

}

@media screen and (max-width: 576px) {
    .wrapper {


        .content {

            .subHeader {
                color: #000;
                text-align: center;
                font-size: 26px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                margin-top: 80px;
                margin-bottom: 50px;

                .highlight {
                    color: #DC633B;
                }
            }

            .rocket {
                height: 240px;
                width: 240px;
            }

            .headerAndButton {
                flex-direction: column;
                gap: 20px;

                p {
                    color: #DC633B;
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    flex: 0.7;
                }
            }
        }

        .multiImage {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 150px;

            img {
                height: 115px;
            }

            .one {
                padding-top: 40px;
            }

            .two {
                padding-top: 20px;
            }
        }
    }
}
