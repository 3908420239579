.wrapper {
    padding-top: 80px;
    padding-bottom: 95px;
    text-align: center;

    h1 {
        color: #000;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 9px;
    }

    .mainContent {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        gap: 50px;
        align-items: center;


        .leftContent {
            height: 360px;
            width: 250px;
            padding-top: 20px;

            .clientWrapper {
                width: 250px;
                height: 70px;
                border-radius: 16px;
                background: #FFE4D2;
                align-items: center;
                cursor: pointer;

                .partnerLogo {
                    height: 34px;
                    min-width: 90px;
                    margin: auto;
                    padding-top: 18px;

                }

                &.disabled {
                    background: #fff;
                }
            }

            .nextArrow {
                cursor: pointer;
                // position: absolute;
                // bottom: -20px;
                // right: 60px;
                transform: rotate(180deg);
                // z-index: 2;
            }

            .prevArrow {
                cursor: pointer;
                // position: absolute;
                // top: -20px;
                // right: 0;
                z-index: 2;
                // top: -20px;
            }

        }

        .rightContent {
            display: flex;
            gap: 19px;
            text-align: left;
            position: relative;

            .quotation {
                width: 132px;
                height: 132px;
                position: absolute;
                right: 0;
                top: -120px;
            }

            .partnerImage {
                width: 284px;
                height: 284px;
                object-fit: cover;
            }

            .textWrapper {
                max-width: 368px;

                h2 {
                    color: #DC633B;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                h3 {
                    color: #DC633B;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .review {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 129%;
                    /* 18.06px */
                    padding-top: 20px;
                    padding-bottom: 37px;

                }

                .occup {
                    color: #4B4B4B;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }



        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding: 30px 12px;

        h1 {
            font-size: 26px;
        }

        p {
            font-size: 14px;
            padding: 9px 20px 0px;
        }

        .mainContent {
            flex-direction: column;

            .rightContent {
                flex-direction: column;

                .partnerImage {
                    width: 335px;
                    height: 335px;
                    margin-bottom: 0px;
                }

                .textWrapper {
                    // max-width: 368px;
                    padding: 10px;

                    .quotationMob {
                        width: 72px;
                        height: 46px;
                        transform: rotate(180deg);
                    }

                    h2 {
                        font-size: 20px;
                    }

                    h3 {
                        font-size: 18px;
                    }

                    .review {
                        font-size: 12px;
                        /* 18.06px */
                        padding: 20px 0px 30px;

                    }

                    .occup {
                        padding: 0;
                    }
                }
            }
        }
    }
}
