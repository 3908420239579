.wrapper {
    background: #000;
    margin-top: 40px;

    .wrapperCont {
        h2 {
            text-align: center;
            color: #fff;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            padding-top: 67px;
            padding-bottom: 70px;
        }

        .mainCont {
            display: flex;
            gap: 87px;
            padding-bottom: 70px;

            .left {
                flex: 0.5;

                p {
                    text-align: justify;
                    color: #fff;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }

                .steps {
                    padding: 20px 10px;
                    border-radius: 20px;
                    background: #fff;
                    margin-top: 28px;
                    display: flex;
                    justify-content: center;

                    .items {
                        flex: 0.3;
                        padding: 0px 12px;

                        h3 {
                            color: #DC633B;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            margin-bottom: 9px;
                        }

                        p {
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                        }
                    }

                    .bordered {
                        border-right: 0.5px solid #000;
                        border-left: 0.5px solid #000;
                    }
                }
            }

            .right {
                height: 415px;
                width: 100%;
                flex: 0.5;
                border-radius: 20px;
                background: #D9D9D9;
            }

        }
    }

    @media screen and (max-width: 576px) {
        .wrapperCont {
            .mainCont {
                padding: 0px 20px 30px;
                flex-direction: column;

                .right {
                    flex: none;
                }
            }
        }
    }
}
