.wrapper {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  margin-top: 50px;
  h1 {
    color: #3e3e3e;
    font-size: 40px;
    font-weight: 500;
    line-height: normal;
    line-height: 100%;
    text-align: center;
  }

  .buttonCont {
    max-width: fit-content;
    margin: 40px auto;
  }

  .descMin {
    color: #303030;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    line-height: 114%;
    text-align: center;
    margin-bottom: 40px;
  }


  .cardsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 80px;

    gap: 20px;

    .cards {
      flex: 0.25;
      border-radius: 20px;
      border: 1px solid #000;
      background: white;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .icon {
        width: 36px;
        height: 36px;
      }
      h2 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      p {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;

      .cards {
        p {
          font-size: 14px;
        }
      }
    }

    
  }
}