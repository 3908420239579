.header {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    .logoImage {
      width: 168px;
      height: 37.5px;
      cursor: pointer;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        float: left;
        color: #151515;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        padding: 0 15px;
        cursor: pointer;
        position: relative;
        /* 18px */
        .dropdown {
          position: absolute;
          z-index: 1;
          top: calc(100% + 10px);
        }
      }
    }

    .buttonWrapper {
      display: flex;
      gap: 30px;
    }
  }
}

.resourceDropdown {
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  text-align: left;
  .title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    margin-bottom: 10px;
  }
  .linkList {
    display: flex;
    gap: 15px;
    flex-direction: column;
    a {
      display: flex;
      align-items: normal;
      gap: 6px;
      img {
        width: 25px;
        height: 25px;
      }
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 200% */
    }
  }
}
.serviceDropdown {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  text-align: left;
  display: flex;
  .container {
    &:first-child {
      border-right: 1px solid black;
    }
    padding: 20px 27px;
  }
  .title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    margin-bottom: 24px;
    white-space: nowrap;
  }
  .listContainer {
    display: flex;
    gap: 20px;
    .linkList {
      display: flex;
      gap: 15px;
      flex-direction: column;
      &:first-child {
        border-right: 1px solid #b6b6b6;
        padding-right: 20px;
      }
      a {
        display: flex;
        align-items: normal;
        gap: 6px;

        img {
          width: 25px;
          height: 25px;
        }

        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 200% */
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .header {
    .wrapper {
      padding-left: 16px;
      padding-right: 18px;
      position: relative;

      .logoMobile {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }

      .burgerIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .mobileHeader {
        position: absolute;
        top: 75px;
        right: 0;
        left: 0;
        bottom: 0;
        height: calc(100vh - 75px);
        background: white;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .topSection {
          .each {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 200% */
            padding: 16px 16px;
          }

          hr {
            border: 0.5px solid #FFBF92;
          }

          .resourceDropdown {
            padding: 10px 20px;
            background-color: #fff;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0);
            min-width: 200px;
            text-align: left;
            .title {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 200% */
              margin-bottom: 10px;
            }
            .linkList {
              display: flex;
              gap: 15px;
              flex-direction: column;
              a {
                display: flex;
                align-items: normal;
                gap: 6px;
                img {
                  width: 25px;
                  height: 25px;
                }
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px; /* 200% */
              }
            }
          }

          .serviceDropdown {
            background-color: #fff;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0);
            min-width: 200px;
            text-align: left;
            display: flex;
            flex-direction: column;
            .container {
              &:first-child {
                border-right: 1px solid black;
              }
              padding: 5px 16px;
            }
            .title {
              color: #000;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 200% */
              margin-bottom: 10px;
              white-space: nowrap;
            }
            .listContainer {
              display: flex;
              gap: 20px;
              .linkList {
                display: flex;
                gap: 10px;
                flex-direction: column;
                &:first-child {
                  border-right: 1px solid #b6b6b6;
                  padding-right: 20px;
                }
                a {
                  display: flex;
                  align-items: normal;
                  gap: 6px;
          
                  img {
                    width: 25px;
                    height: 25px;
                  }
          
                  color: #000;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 28px; /* 200% */
                  white-space: nowrap;
                }
              }
            }
          }
        }

        .buttonContOne {
          display: flex;
          gap: 10px;
          padding: 20px 16px;
          .each {
            width: 100%;
          }
        }
      }
    }
  }
}
