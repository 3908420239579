.wrapper {
    position: relative;
    padding-top: 60px;

    .bgContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: #151515;
        height: 1170px;
    }

    .mainContent {
        text-align: center;

        h1 {
            color: #FFF;
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        p {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 4px;
        }

        .perksSection {
            display: flex;
            gap: 145px;
            margin-top: 60px;
            margin-bottom: 60px;
            align-items: center;

            .dashboard {
                max-width: 385px;
                max-height: 260px;
            }

            .serviceCards {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;

                .serviceCard {
                    display: flex;
                    flex-direction: column;
                    padding: 19.1px 24.3px;
                    width: 240px;
                    text-align: left;

                    .serviceItem {
                        width: 37.8px;
                        height: 37.8px;
                        margin-bottom: 11px;
                    }

                    h2 {
                        color: #FFF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    p {
                        color: #ACACAC;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }


            }
        }

        .buttonWrapper {
            margin-bottom: 60px;
        }

        .easyContainer {
            margin-top: 38px;
            padding: 20px 30px;
            border-radius: 25px 25px 0px 0px;
            background: #FFF;
            position: relative;
            display: inline-flex;
            flex-direction: column;
            gap: 97px;
            overflow: hidden;

            .easyWrapper {
                display: grid;
                grid-template-columns: repeat(3,1fr);
                gap: 50px;
                justify-content: space-between;
                z-index: 1;

                .easyItem {
                    max-width: 234px;

                    .icon {
                        height: 30px;
                        width: 30px;
                        margin-bottom: 18px;
                    }

                    p {
                        color: #000;
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }

            .patternOne {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
            }


        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        margin-bottom: 80px;
        .bgContainer {
            height: 1000px;
        }

        .mainContent {

            h1 {
                font-size: 28px;
                padding: 0 20px;
            }

            p {
                font-size: 14px;
                padding: 0 20px;
            }


            .perksSection {
                padding: 0 20px;
                flex-direction: column;
                gap: 50px;
                margin-top: 40px;
                margin-bottom: 0px;
                align-items: center;

                .dashboard {
                    max-width: 250px;
                    max-height: 170px;
                }

                .serviceCards {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    gap: 2px;
                    margin-bottom: 30px;

                    .serviceCard {
                        padding: 5px;
                        width: 147px;
                        margin-bottom: 10px;

                        .serviceItem {
                            width: 30px;
                            height: 30px;
                            margin-bottom: 11px;
                        }
                    }


                }
            }

            .easyContainerTwo {
                // margin-top: 20px;
                // background-color: aqua;
                // width: 390px;
                // margin: 50px auto;
                .easyCont {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    padding: 20px;
                    border-radius: 20px;
                    // margin: auto;
                    margin: 30px 10px 20px;
                    max-width: 270px;
                    
                    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);

                    .easyMob {
                        width: 40px;
                        height: 40px;
                        margin: auto;
                    }
    
                    .textMob {
                        color: #000;
                        margin-top: 16px;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                
            }
        }
    }
}