.wrapper {
    position: relative;

    .contentWrapper {

        .header {
            color: #161616;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 48px */
            margin-bottom: 40px;
        }

        .content {
            display: flex;
            justify-content: space-between;
            gap: 60px;

            .left {
                flex: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .first {
                    color: #DC633B;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    /* 24px */
                }

                .second {
                    color: #222;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 106%;
                    /* 19.08px */
                    margin: 30px 0px;
                }

                .list {


                    .items {

                        display: flex;
                        gap: 16px;
                        justify-content: flex-start;
                        align-items: center;
                        margin: 22px 0px;

                        .stars {
                            width: 23px;
                            height: 23px;
                        }

                        p {
                            color: #4A4A4A;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            /* 18px */
                        }
                    }
                }

            }

            .right {
                display: flex;
                gap: 25px;
                flex-wrap: wrap;
                justify-content: flex-end;

                flex: 40%;

                .items {
                    // flex: 1;
                    border-radius: 15px;
                    border: 1px solid #000;
                    background: #FFF;
                    // height: 204px;
                    padding: 50px 22px;
                    width: 186px;

                    .linking {
                        height: 55px;
                        width: 57px;
                        margin-bottom: 11px;
                    }

                    h2 {
                        color: #1E1E1E;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        max-width: 120px;
                    }

                    p {
                        color: #252525;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        /* 24px */
                        max-width: 120px;
                    }

                    h3 {
                        color: #FFF;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 106%;
                        /* 19.08px */
                    }
                }


                .diff {
                    // flex: 1;
                    width: 233px;
                    height: 187px;
                    background: #DC633B;
                    border: none;
                    padding: 22px;
                    margin-top: 50px;
                }
            }
        }
    }


    .banner {
        padding: 67px 0px;
        background: #DC633B;
        margin-top: 80px;

        .content {
            text-align: center;
            max-width: 660px;
            margin: 0 auto;

            h1 {
                color: #FFF;
                font-size: 42px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 42px */
            }

            p {
                color: #FFF;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                /* 24px */
                margin: 30px 0px;
            }

            .buttonCont {
                border-radius: 10px;
                background: #262626;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                width: 170px;
                margin: 0 auto;
                cursor: pointer;

                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        .contentWrapper {
            padding: 0px 16px;

            .header {
                font-size: 32px;
            }
            .content {
                flex-direction: column;

                .left {
                    text-align: center;
                    .first {
                        font-size: 18px;
                    }

                    .list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 16px;
                        .items {

                            p {
                                font-size: 16px;
                            }
                        }
                    }
                }

                .right {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                    justify-content: center;

                    flex: 1;

                    .items {
                        // flex: 1;
                        border-radius: 15px;
                        border: 1px solid #000;
                        background: #FFF;
                        // height: 204px;
                        padding: 50px 22px;
                        width: 90px;
                        height: 80px;

                        .linking {
                            height: 55px;
                            width: 57px;
                            margin-bottom: 11px;
                        }

                        h2 {
                            color: #1E1E1E;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            max-width: 120px;
                        }

                        p {
                            color: #252525;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            /* 24px */
                            max-width: 120px;
                        }

                        h3 {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 106%;
                            /* 19.08px */
                        }
                    }


                    .diff {
                        // flex: 1;
                        width: 103px;
                        height: 97px;
                        background: #DC633B;
                        border: none;
                        padding: 22px;
                        margin-top: 50px;
                    }
                }
            }
        }
    }

}
