.wrapper {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .outlineButton {
    border: 1px solid #DC633B;
    padding: 14px 20px;
    border-radius: 40px;
    color: #DC633B;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      max-width: fit-content;
  }


  .mainImage {
    margin: 20px 0px;
  }

  h2 {
    color: #000000;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 30px;
  }

  p {
    color: #000000;
      font-size: 24px;
      font-weight: 400;
      line-height: normal;
  }
}
