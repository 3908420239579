.collabBanner {
    border-radius: 15px;
    background: #DC633B;
    width: 903px;
    padding: 44px 90px 50px;
    margin: auto;
    position: relative;
    margin-top: 50px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .rocket {
        position: absolute;
        left: 20px;
        top: -20px;
    }

    .pattern {
        position: absolute;
        right: 0;
        bottom: 0;
        border-bottom-right-radius: 15px;
    }

    h2 {
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.42px;
    }

    .button {
        display: inline-flex;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        z-index: 2;
        cursor: pointer;

        p {
            color: #363636;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.24px;
        }

        .arrow {
            width: 24px;
            height: 24px;

            transform: rotate(180deg);
        }
    }

    @media screen and (max-width: 576px) {
        border-radius: 15px;
            width: auto;
            padding: 57px 24px 97px;
            margin: auto 16px;
            flex-direction: column;
            gap: 10px;
            margin-top: 70px;
            margin-bottom: 40px;

            .rocket {
              position: absolute;
              left: 20px;
              top: -20px;
            }

            .pattern {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 327px;
              border-bottom-right-radius: 15px;
              border-bottom-left-radius: 15px;
            }

            h2 {
              font-size: 24px;
              text-align: center;
            }
    }
}

.requirementWrapper {
    padding: 0px 200px;
    align-items: center;

    @media screen and (max-width: 576px) {
        padding: 0px 16px;
    }

    .right {
        text-align: justify;
        h2 {
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            margin-bottom: 20px;
        }

        p {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }

        img {
            width: 100%;
        }
    }

    @media screen and (max-width: 576px) {
        flex-direction: column;
        padding-top: 30px;

        .right {
            padding: 0px 20px;
            text-align: center;
        }
    }
}

.solutionWrapper {
    background: #000;
    margin-top: 40px;

    .wrapperCont {
        h2 {
            text-align: center;
            color: #fff;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            padding-top: 67px;
            padding-bottom: 70px;
        }

        .mainContTwo {
            flex-direction: row-reverse;
            // margin-top: 20px;
        }

        .mainCont {
            display: flex;
            gap: 87px;
            padding-bottom: 70px;
            margin-top: 20px;
            align-items: center;

            .left {
                flex: 0.5;

                h3 {
                    text-align: justify;
                    color: #fff;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    margin-bottom: 16px;
                }

                p {
                    text-align: justify;
                    color: #fff;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }

            }

            .right {
                // height: 415px;
                width: 100%;
                flex: 0.5;
                border-radius: 20px;

                img {
                    object-fit:cover;
                }
            }

        }
    }

    @media screen and (max-width: 576px) {
        .wrapperCont {
            .mainCont {
                flex-direction: column;
                padding: 0px 20px;

                .right {
                    flex: none;
                    margin-bottom: 20px;

                    img {
                        width: 90vw;
                    }
                }
            }
        }
    }
}



.impactWrapperCont {
    padding: 0px 200px;
    h2 {
        text-align: left;
        color: #DC633B;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        padding-top: 67px;
        padding-bottom: 30px;
    }

    .mainCont {
        padding-bottom: 70px;

        .left {

            p {
                text-align: left;
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }
            
            img {
                margin-top: 50px;
                width: 100%;
            }
            
        }

    }

    @media screen and (max-width: 576px) {
        padding: 0px 20px;
    }
}
