.wrapper {
  position: relative;

  .bgContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #151515;
    height: 500px;
  }

  .bgContainerBottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #151515;
    height: 700px;

    @media screen and (max-width: 576px) {

      height: 900px;
    }
  }

  .mainContent {
    // text-align: center;

    .partners {
      padding: 28px 0 80px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 77px;

      .logoImage {
        width: 145px;
        height: 40px;
        object-fit: contain;
      }

      @media screen and (max-width: 576px) {

        padding: 28px 16px 60px;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;

        .logoImage {
          width: 100px;
          height: 25px;
          margin: 0 auto;
        }
      }
    }

    .appointment {
      border-radius: 19px;
      border: 1.5px solid #000;
      padding: 40px 117px;
      background: #fff;
      display: flex;
      gap: 30px;

      .left {
        text-align: left;

        .topText {
          color: #DC633B;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .header {
          color: #000;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 20px 0px 40px;
        }

        .buttonCont {
          width: 226px;
        }
      }

      .right {
        margin: auto 0;

        .logoImage {
          width: 160px;
          height: 160px;
          object-fit: contain;
        }
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        margin: 0 16px;
        padding: 25px 20px;
        .left {
          text-align: center;

          .header {
            font-size: 24px;
            margin: 20px 0px 40px;
          }

          .buttonCont {
            width: 226px;
            margin: auto;
          }
        }

        .right {
          margin: auto ;

          .logoImage {
            width: 159px;
            height: 165.5px;
          }
        }
      }
    }

    .requirement {
      margin-top: 75px;

      .headerSection {
        h1 {
          color: #2c2c2c;
          text-align: center;
          font-size: 48px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 48px */
        }

        .buttonCont {
          max-width: fit-content;
          margin: 30px auto;
        }

        p {
          color: #434343;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 114.5%;
          /* 22.9px */
          /* 28.32px */
          max-width: 833px;
          margin: 0 auto;
        }
      }

      .cardsSection {
        display: flex;
        gap: 27.5px;
        margin-top: 40px;

        .cards {
          border-radius: 19.685px;
          border: 0.656px solid #DC633B;
          padding: 16px 28px;
          flex: 1;

          .logoImage {
            width: 39.37px;
            height: 39.37px;
          }

          h2 {
            color: #000;
            text-align: left;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 24px */
            margin: 14px 0px;
          }

          p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            text-align: left;
            font-weight: 400;
            line-height: 100%;
            /* 14px */
          }
        }
      }
    }

    .sureSection {
      margin-top: 75px;

      .headerSection {
        h1 {
          color: #161616;
          text-align: center;
          font-size: 56px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 56px */
          /* 48px */
        }

        .buttonCont {
          max-width: fit-content;
          margin: 30px auto;
        }

        p {
          color: #161616;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 118%;
          /* 28.32px */
          /* 22.9px */
          /* 28.32px */
          max-width: 838px;
          margin: 0 auto;
        }
      }

      .cardsSection {
        display: flex;
        gap: 20px;
        margin-top: 20px;

        .cards {
          border-radius: 15.412px;
          border: 1px solid #000;
          background: #fff;
          padding: 20px 0px 20px 20px;

          .logoImage {
            width: 50px;
            height: 50px;
          }

          h2 {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            /* 24px */
            margin: 15px 0px;
          }

          p {
            color: #000;
            text-align: left;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            /* 14px */
          }

          .buttonCont {
            border-radius: 27px;
            border: 0.5px solid #000;
            padding: 10px 0px;
            width: 121px;
            text-align: center;
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 15px;
          }

          hr {
            margin: 15px 0px;
            width: 150px;
          }

          h2 {
            color: #DC633B;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          ul {
            margin-left: 20px;

            li {
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }

    .clientSection {
      position: relative;
      margin: 100px 0px 0px;
      padding-bottom: 60px;

      .topSection {
        display: flex;
        gap: 124px;

        .left {
          .header {
            color: #fff;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 56px */
          }

          .desc {
            color: #fff;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 24px */
            margin: 50px 0px;
          }

          .buttonCont {
            width: 160px;
          }
        }

        .right {
          .card {
            padding: 30px 30px 40px;
            border: 2px solid #DC633B;
            width: 450px;
            background: #fff;

            .icon {
              width: 70px;
              height: 70px;
            }

            h3 {
              color: #161616;
              font-size: 32px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 32px */
              margin: 30px 0px 20px;
              width: 220px;
            }

            p {
              color: #000;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 124%;
              /* 22.32px */
            }
          }

          .bottomSec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .right {
              color: #fff;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .left {
              color: #fff;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
              /* 24px */
            }
          }
        }
      }
    }
  }
}

.orangeDiv {
  background-color: #DC633B;
  padding: 20px 0 30px;
}

.pricingFpp {
  padding: 80px;
  h2 {
    color: #2C2C2C;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    color: #2C2C2C;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }

  .cardsCont {
    display: flex;
    gap: 20px;
    margin-top: 40px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }

    .card {
      border: 1px solid #000;
      border-radius: 16px;
      padding: 30px;
      flex: 3.33;
      position: relative;

      &.diff {
        background-color: #FFE4D2;
      }

      h3 {
        color: #000;
        text-align: left;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 15px 0;
      }

      p {
        color: #000;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      h2 {
        color: #DC633B;
        text-align: left;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 15px;
      }

      hr {

        opacity: 0.3;
        margin: 15px 0;
      }

      ul {
        margin-left: 20px;
        color: #DC633B;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 15px;
      }

      .outline {
        padding: 8.5px 16px;
        border: 1px solid #000;
        width: 100px;
        border-radius: 20px;
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &:hover {
          cursor: pointer;
        }
      }

      .mainBtn {
        position: absolute;
        left: 30px;
        bottom: 30px;

        @media screen and (max-width: 576px) {
          // position: relative;
          // left: 0;
          // right: 0;
          bottom: 10px;
        }
      }
    }
  }
}
.services {
  background: #131313;
  padding: 80px 0;
  h1 {
    color: #fff;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .cardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 50px;
    margin-bottom: 100px;

    .cards {
      flex: 25%;
      padding: 26px 35px;
      border-radius: 20px;
      border: 0.8px solid #717171;
      background: #fff;
      max-width: 480px;

      h1 {
        text-align: left;
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 32px */
      }

      p {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 15px 0;
      }

      .icon {
        width: 50px;
        height: 50px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 50px 16px 10px;

    h1 {
      font-size: 24px;
    }

    .cardsContainer {
      .cards {
        h1 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}

.packages {
  background: #131313;
  padding: 80px 0;
  // h1 {
  //   color: #fff;
  //   text-align: center;
  //   font-size: 48px;
  //   font-style: normal;
  //   font-weight: 500;
  //   line-height: normal;
  // }
}

.dominate {
  padding: 100px 0px;
  .headerSection {
    h1 {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 50px;
    }

    h3 {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 114.5%;
      /* 22.9px */
      margin: 20px auto 40px;
      max-width: 706px;
    }
  }



  .cardsSection {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    .cards {
      border-radius: 20px;
      border: 1px solid #000;
      background: white;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
      .icon {
        width: 36px;
        height: 36px;
      }
      h2 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      p {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }


  }

  .cardsSectionAlter {

    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 576px) {
    .headerSection {
      h1 {
        font-size: 24px;

      }

      h3 {
        font-size: 14px;

      }
    }


    .cardsSection {
      // grid-template-columns: repeat(2, 1fr);
      flex-direction: column;
      .cards {
        h2 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
        }
      }
    }
    padding: 40px 16px;


  }
}

.expWrapper {
  padding-top: 100px;
}
