.innerContainer {
  padding: 50px;

  h5 {
    color: #161616;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 56px */
    margin-bottom: 50px;
  }
  p {
    color: #161616;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 118%; /* 23.6px */
    margin-bottom: 22px;
  }
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 80px 0;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  div {
    padding: 10px;
  }
}
.title {
  text-align: left;
}
.hightlighted {
  background: #dc633b;
  font-weight: 700;
  color: #fff;
  &.bottom {
    margin-top: 30px;
  }
}
.value {
  font-weight: 500;
}
.cta {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  color: #dc633b;
}
