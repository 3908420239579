.wrapper {
  padding: 100px 0;
  @media screen and (max-width: 576px) {
    padding: 50px 16px;
  }
}
.head{
  color: #000;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 48px */
  margin-bottom: 40px;
}
.subhead{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 118%; /* 23.6px */
  max-width: 800px;
    margin: 0 auto;
}
.list{
  display: flex;
    justify-content: center;
    gap: 20px;
  margin-top: 40px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
  .card{
    border-radius: 17.5px;
    border: 1px solid #000;
    background: #FFF;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 320px;
    .title{
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .subTitle{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 128%; /* 20.48px */
    }
  }
}
