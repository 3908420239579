.wrapper {
    background: #DC633B;
    padding: 50px;
    margin-top: 60px;

    hr {
        background: #C8FBFE;
        margin: 25px 0px;
    }

    .mapStyle {
        border: 0;
        // width: 100%;
        margin-top: 30px;
    }

    .main {
        display: flex;
        justify-content: space-between;
        gap: 50px;

        .details {
            flex: 35%;

            .logoMain {
                width: 168px;
                height: 38px;
                margin-bottom: 31px;
            }

            p {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .dfySection {
            flex: 15%;

            p {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: 10px;
                margin-bottom: 33px;
            }

            ul {
                list-style-type: none;

                li {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 15px;
                    cursor: pointer;
                }
            }
        }


        .contactUs {
            flex: 30%;

            p {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 10px;
                margin-bottom: 33px;
            }

            .subsButton {
                cursor: pointer;
                margin-top: 14px;
                border-radius: 10px;
                background: #FFF;
                color: #DC633B;
                padding: 9px 27px;
                display: inline-flex;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                &:hover {

                    transform: scale(1.05);
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                }
            }

            ul {
                list-style-type: none;
                margin-top: 20px;

                li {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    .bottomSection {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .first {

            .icons {
                display: flex;
                gap: 15px;

                .socialLogo {
                    width: 20px;
                    height: 20px;
                }
            }

            p {
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 23px;
            }
        }

        .badgeIcon {
            width: 60px;
            height: 60px;
        }

        .stickerIcon {
            width: 133px;
            height: 41px;
        }

        .ratingSection {

            p {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .middle {
                display: flex;
                gap: 5px;
                align-items: center;

                p {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }

                .starsWrapper {

                    display: flex;

                    .stars {
                        width: 20px;
                        height: 20px;
                    }
                }


            }

            .googleBefore {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                span {
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        .review {

            .starsWrapper {

                display: flex;
                gap: 5px;

                .stars {
                    width: 15px;
                    height: 15px;
                }
            }


            p {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-top: 4px;
            }

        }
    }
}


@media screen and (max-width: 576px) {
    .wrapper {
        padding: 30px 16px;

        .main {
            flex-direction: column;
            gap: 15px;


            .details {
                flex: 35%;

                .logoMain {
                    width: 168px;
                    height: 38px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 14px;
                }
            }

            .mobView {
                display: flex;

                .dfySection {
                    flex: 15%;

                    p {
                        font-size: 16px;
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }

                    ul {
                        list-style-type: none;

                        li {
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            // .dfySection {
            //     flex: 15%;

            //     p {
            //         font-size: 16px;
            //         margin-top: 10px;
            //         margin-bottom: 20px;
            //     }

            //     ul {
            //         list-style-type: none;

            //         li {
            //             font-size: 14px;
            //             margin-bottom: 10px;
            //         }
            //     }
            // }


            .contactUs {
                flex: 30%;

                p {
                    margin-bottom: 16px;
                }

                .subsButton {
                    cursor: pointer;
                    margin-top: 14px;
                    border-radius: 10px;
                    background: #FFF;
                    color: #DC633B;
                    padding: 9px 27px;
                    display: inline-flex;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
}