.wrapper {
    padding-top: 180px;
    position: relative;
    background: white;

    @media screen and (max-width: 576px) {
        flex-direction: column;
        padding-top: 150px;
    }

    .pattern {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        height: 520px;
        background: #DC633B;

        @media screen and (max-width: 576px) {
            height: 820px;
        }
    }

    .main {
        display: flex;
        gap: 40px;
        justify-content: space-around;
        @media screen and (max-width: 576px) {
            flex-direction: column-reverse;
        }

        .left {
            z-index: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 50px;
    
            @media screen and (max-width: 576px) {
                padding-left: 16px;
                padding-right: 16px;
            }
    
            h1 {
                color: #000;
                font-size: 48px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                text-align: left;
            }
    
            .alter {
                color: white;
            }
    
            p {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                padding: 40px 0px;
                text-align: left;
            }

            .btnCnt {
                width: 120px;
                margin-top: 30px;
            }
    
        
        }
    
        .right {
    
            @media screen and (max-width: 576px) {
                padding-left: 16px;
            }
            .mainImage {
                width: 223px;
                margin: auto;
            }
        }
    }

    .textComp {
        padding: 100px 200px;

        @media screen and (max-width: 576px) {
            padding: 100px 16px;
        }

        p {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            padding: 40px 0px;
            text-align: center;
        }
    }

    
}
