.wrapper {
    padding: 13px 54px;
    border-radius: 55px;
background: #DC633B;
display: inline-flex;

color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
}
