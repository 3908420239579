.wrapper {
    background: #DC633B;
    position: relative;
    height: 100vh;

    .leftAbs {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 413px;
        height: 347px;
    }

    .middleAbs {
        position: absolute;
        left: 35%;
        bottom: 0;
        width: 397px;
        height: 249px;
    }

    .rightAbs {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 458px;
        height: 332px;
    }

    .contentWrapper {
        padding-top: 140px;

        @media screen and (min-width: 1800px) {

            padding-top: 300px;
        }

        .headerText {
            color: #000;
            text-align: center;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 56px */
            max-width: 821px;
            margin: 0 auto 70px;

        }

        .highlight {
            color: #FFF;
        }

        .blocksWrapper {
            display: flex;
            gap: 0;

            .blocks {
                flex: 1;
                h3 {
                    color: #FFF;
                    text-align: center;
                    font-size: 56px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 56px */

                }

                p {
                    color: #FFF;
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 24px */
                    margin-top: 36px;
                }
            }

            .nonZero {
                border-left: 1px solid #fff;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        background: #DC633B;
        position: relative;
        height: 100vh;

        .leftAbs {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 128px;
            height: 106px;
        }

        .middleAbs {
            position: absolute;
            left: 35%;
            bottom: 0;
            width: 133px;
            height: 86px;
        }

        .rightAbs {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 113px;
            height: 86px;
        }

        .contentWrapper {
            padding-top: 140px;
            padding-left: 16px;
            padding-right: 16px;

            .headerText {
                color: #000;
                text-align: center;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 56px */
                max-width: 821px;
                margin: 0 auto 70px;

            }

            .highlight {
                color: #FFF;
            }

            .blocksWrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 30px 0px;

                .blocks {
                    flex: 1;
                    padding: 10px;
                    h3 {
                        color: #FFF;
                        text-align: center;
                        font-size: 36px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%; /* 56px */

                    }

                    p {
                        color: #FFF;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%; /* 24px */
                        margin-top: 36px;
                    }
                }

                .nonZero {
                    border-left: 1px solid #fff;
                }
            }
        }
    }
}
