.wrapper {
  position: relative;
  max-width: 100vw;
  overflow-x: hidden;
  .bgContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #151515;
    height: 3100px;
    // z-index: 1;
  }

  .bgContainerBottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #151515;
    height: 3800px;
  }

  .partners {
    display: flex;
    justify-content: space-between;
    padding: 35px 10px;
    background-color: #151515;
    z-index: 2;
    position: relative;

    .logoImage {
      width: 145px;
      height: 35px;
    }
  }

  .mainContent {
    text-align: center;

    .whoHeader {
      color: #fff;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 50px;
      margin-top: 25px;

      opacity: 0;
    }

    .thumbnail {
      width: 800px;
      height: 450px;

      &.mobile {
        width: 328px;
        height: 450px;
      }
    }

    .valueHeader {
      color: #1b1b1b;
      font-size: 56px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 60px 0px;
      opacity: 0;
    }
    .videoContainer {
      max-width: 700px;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      border-radius: 50px;
      overflow: hidden;
      iframe {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
        margin: 0 auto;
        object-fit: cover;
      }
    }

    .valueCards {
      display: flex;
      justify-content: space-around;

      .valueCard {
        border-radius: 28px;
        border: 1px solid #000;
        padding: 26px 34px 0px 34px;
        max-width: 280px;
        background: #fff;
        display: flex;
        flex-direction: column;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2 {
            color: #000;
            font-size: 24px;
            text-align: left;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .valueItem {
            height: 37px;
            width: 37px;
          }
        }

        p {
          color: #000;
          text-align: justify;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 25px;
          margin-bottom: 45px;
        }
        .bottomImage {
          width: 100%;
          height: auto;
          object-fit: cover;
          margin-top: auto;
        }
      }
    }

    .serviceHeader {
      display: flex;
      padding-top: 60px;
      padding-bottom: 40px;
      justify-content: space-between;

      .serviceHeaderText {
        color: #fff;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .serviceCards {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 27px;
      padding-bottom: 50px;

      .serviceCard {
        display: flex;
        flex-direction: column;
        gap: 9px;
        padding: 19.1px 24.3px;
        width: 310px;
        text-align: left;

        border-radius: 18.9px;
        border: 0.9px solid #717171;

        background: #fff;
        gap: 9px;

        .serviceItem {
          width: 37.8px;
          height: 37.8px;
        }

        h2 {
          color: #717171;
          font-size: 21.6px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        p {
          color: #717171;
          font-size: 14.4px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    .bgContainer {
      height: 500px;
    }

    .bgContainerBottom {
      height: 150vh;
    }

    .mainContent {
      .partners {
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        padding: 25px;

        .logoImage {
          width: 144.014px;
          height: 34.909px;
        }
      }

      .whoHeader {
        color: #fff;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 1;
      }

      .videoContainer {
        max-width: 90vw;
      }

      .valueHeader {
        color: #1b1b1b;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 60px 0px 30px;
        opacity: 1;
      }

      .valueCards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .valueCard {
          max-width: 250px;

          .top {
            .valueItem {
              height: 35px;
              width: 35px;
            }
          }
        }
      }

      .serviceHeader {
        padding-bottom: 25px;
        flex-direction: column;
        gap: 20px;

        .serviceHeaderText {
          font-size: 26px;
        }

        .switchButton {
          margin: 0 auto;
        }
      }

      .serviceCards {
        gap: 2px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-around;
        flex-direction: column;

        .serviceCard {
          margin-bottom: 10px;
          gap: 9px;
          padding: 10px 13px;
          width: 92%;
          text-align: left;

          border-radius: 18.9px;
          border: 0.9px solid #717171;

          background: #fff;
          gap: 9px;

          .serviceItem {
            width: 30px;
            height: 30px;
          }

          h2 {
            color: #717171;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          p {
            color: #717171;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}
