.wrapper {
  position: relative;

  .bgColor {
    background: #DC633B;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
  }

  .bgColorOne {
    background: #000;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    height: 146vh;
  }

  .contentWrapper {
    .partners {
      display: flex;
      justify-content: space-between;
      padding: 22.5px 0;
      margin-bottom: 100px;

      .logoImage {
        width: 145px;
        height: 35px;
      }
    }

    .services {
      h1 {
        color: #fff;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .cardsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        margin-top: 50px;
        margin-bottom: 100px;

        .cards {
          flex: 25%;
          padding: 26px 35px;
          border-radius: 20px;
          border: 0.8px solid #717171;
          background: #fff;
          max-width: 480px;

          h1 {
            text-align: left;
            color: #000;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 32px */
          }

          p {
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 15px 0;
          }

          .icon {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    .requirement {
      h1 {
        color: #fff;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .buttonContainer {
        margin: 40px auto;
        max-width: 655px;
      }

      p {
        color: #e3e3e3;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 114.5%;
        /* 22.9px */
        margin-bottom: 40px;
      }

      .cardsContainer {
        display: flex;
        gap: 27.5px;

        margin-bottom: 100px;

        .cards {
          border-radius: 19.685px;
          border: 1.2px solid #DC633B;

          background: #fff;
          padding: 16px 28px;
          h1 {
            color: #000;
            text-align: left;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 24px */
            margin: 14px 0;
          }

          p {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            text-align: left;
            margin-bottom: 0;
          }

          .icon {
            width: 39.37px;
            height: 39.37px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    position: relative;
    .bgColor {
      background: #DC633B;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 110px;
    }

    .bgColorOne {
      background: #000;
      position: absolute;
      top: 110px;
      left: 0;
      right: 0;
      height: 100%;
    }

    .contentWrapper {
      .partners {
        .logoImage {
          height: 50px;
          width: 100px;
          padding: 10px 10px 0px;
        }
      }

      .services {
        padding: 0px 16px;
        h1 {
          font-size: 32px;
        }

        .cardsContainer {
          .cards {
            h1 {
              font-size: 24px;
            }

            p {
              font-size: 14px;
            }
          }
        }
      }

      .requirement {
        padding: 0px 6px;
        h1 {
          font-size: 32px;
        }

        p {
          font-size: 14px;
          padding: 0px 10px;
        }

        .cardsContainer {
          flex-direction: column;
          padding: 0px 10px;

          .cards {
            p {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
