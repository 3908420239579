.wrapper {
  position: relative;

  .bgColor {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
  }

  .contentWrapper {
    margin-top: 80px;
    .partners {
      display: flex;
      justify-content: space-between;
      padding: 22.5px 0;
      margin-bottom: 100px;

      .logoImage {
        width: 145px;
        height: 35px;
      }
    }

    h3 {
      color: #2c2c2c;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 48px */
    }

    .textBody {
      display: flex;
      gap: 100px;
      margin-top: 40px;
      margin-bottom: 80px;

      color: #000;
      text-align: justify;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      .left {
        flex: 3;
      }

      .right {
        flex: 3;
      }
      b{
        display: block;
        margin-bottom: 10px;
      }
      p{
        margin-bottom: 20px;
      }
    }
  }

  .dominate {
    padding: 80px 0px;
    background: #131313;

    .headerSection {
      h1 {
        color: #fff;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 48px */
      }

      h3 {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 114.5%;
        /* 22.9px */
        margin-top: 20px;
        max-width: 706px;
        margin: 20px auto 40px;
      }
    }

    .cardsSection {
      display: flex;
      justify-content: space-between;
      gap: 17.5px;

      .cards {
        height: 350px;
        border-radius: 17.5px;
        padding: 17px 26px;
        background: #fff;
        text-align: center;
        flex: 1;

        h2 {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        p {
          color: #000;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 21.88px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    .contentWrapper {
      padding: 0 16px;

      .partners {
        margin-bottom: 50px;
      }

      h3 {
        font-size: 24px;
      }
      h1 {
        font-size: 24px;
        /* 48px */
      }

      .textBody {
        flex-direction: column;
        gap: 0px;
        margin-top: 25px;
        margin-bottom: 50px;
        font-size: 16px;

        .left {
          flex: 3;
        }

        .right {
          flex: 3;
        }
      }
    }

    .dominate {
      padding: 40px 16px 33px;

      .headerSection {
        h1 {
          font-size: 24px;
          /* 48px */
        }

        h3 {
          font-size: 16px;
          margin: 20px auto 30px;
        }
      }

      .cardsSection {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 17.5px;

        .cards {
          height: 150px;
          padding: 15px 21px;
          text-align: left;
          align-items: flex-start;
          justify-content: center;
          display: flex;
          flex-direction: column;

          .logoImage {
            width: 40px;
            height: 40px;
          }

          h2 {
            font-size: 18px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
