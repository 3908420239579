.wrapper {
  padding-top: 120px;

  .headerSection {
    text-align: center;
    h1 {
      color: #000;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    p {
      color: #151515;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }

  .formContainer {
    width: 630px;
    margin: 0 auto;
    padding-top: 20px;

    .rows {
      display: flex;
      // gap: 30px;
      margin-bottom: 30px;
      justify-content: space-between;

      .inputs {
        p {
          margin-bottom: 10px;
        }
      }
    }

    .inputs {
      p {
        margin-bottom: 10px;
      }
    }

    .buttonCont {
      margin-top: 30px;
    }
  }
}
.orSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 0 15px;

  .divider {
    height: 1px;
    width: 266px;
    background: #000;
  }
}

.bottomText {
  color: #151515;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 20px;

  .highlight {
    color: #dc633b;
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    .formContainer {
      width: auto;
      margin: 0 auto;
      padding-top: 20px;
      padding: 20px 16px;

      .rows {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;
        justify-content: space-between;
        // padding: 0 16px;

        .inputs {
          p {
            margin-bottom: 10px;
          }
        }
      }

      .inputs {
        p {
          margin-bottom: 10px;
        }
      }

      .other {
        // padding: 0 16px;
      }

      .buttonCont {
        margin-top: 30px;
      }

      .orSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        padding: 0 15px;

        .divider {
          height: 1px;
          width: 266px;
          background: #000;
        }
      }

      .bottomText {
        color: #151515;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-top: 20px;

        .highlight {
          color: #dc633b;
        }
      }
    }
  }
}
.frameContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 0 15px;

  .frame {
    width: 100%;
    height: 100%;
      min-height: 800px;
    border: none;
  }
}
