.wrapper {
    position: relative;

    .bgColor {
        background: #000;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
    }

    .contentWrapper {

        .partners {
            display: flex;
            justify-content: space-between;
            padding: 22.5px 0;
            margin-bottom: 100px;

            .logoImage {
                width: 145px;
                height: 35px;
            }
        }

        h1 {
            color: #2C2C2C;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 48px */
        }

        .textBody {
            display: flex;
            gap: 100px;
            margin-top: 40px;
            margin-bottom: 80px;

            color: #000;
            text-align: justify;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            .left {
                flex: 3;
            }

            .right {
                flex: 3;
            }
        }

        .complement {
            color: #000;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 125%;
            /* 32px */
            margin-bottom: 40px;
        }

        .cardsSection {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;

            .cards {
                border-radius: 20px;
                border: 1px solid #000;
                background: #fff;
                padding: 40px 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .icon {
                    width: 36px;
                    height: 36px;
                }

                h2 {
                    color: #000;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

    .dominate {
        padding: 80px 0px;
        background: #131313;

        .headerSection {
            h1 {
                color: #FFF;
                text-align: center;
                font-size: 48px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 48px */
            }

            h3 {
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.5%;
                /* 22.9px */
                margin-top: 20px;
                max-width: 706px;
                margin: 20px auto 40px;
            }
        }

        .cardsSection {
            display: flex;
            justify-content: space-between;
            gap: 17.5px;

            .cards {
                height: 350px;
                border-radius: 17.5px;
                padding: 17px 26px;
                background: #FFF;
                text-align: center;
                flex: 1;
                position: relative;

                h2 {
                    color: #000;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 21.88px;
                }

                .bottomImage {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding: 0px 0px;

        .bgColor {
            height: 150px;
        }

        .contentWrapper {
            padding: 0 16px;

            .partners {
                margin-bottom: 50px;
                flex-wrap: wrap;
                gap: 20px;

                .logoImage {
                    height: 40px;
                    width: 100px;
                }
            }

            h1 {
                font-size: 24px;
                /* 48px */
            }

            .textBody {
                flex-direction: column;
                gap: 0px;
                margin-top: 25px;
                margin-bottom: 50px;
                font-size: 16px;

                .left {
                    flex: 3;
                }

                .right {
                    flex: 3;
                }
            }

            .complement {
                font-size: 24px;
            }

            .cardsSection {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 10px;

                .cards {
                    border-radius: 20px;
                    border: 1px solid #000;
                    background: #fff;
                    padding: 40px 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .icon {
                        width: 36px;
                        height: 36px;
                    }

                    h2 {
                        color: #000;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    p {
                        color: #000;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

        .dominate {
            padding: 40px 16px 33px;

            .headerSection {
                h1 {
                    font-size: 24px;
                    /* 48px */
                }

                h3 {
                    font-size: 16px;
                    margin: 20px auto 30px;
                }
            }

            .cardsSection {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                gap: 17.5px;

                .cards {
                    height: 150px;
                    padding: 15px 21px;
                    text-align: left;
                    align-items: flex-start;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;

                    .logoImage {
                        width: 40px;
                        height: 40px;
                    }

                    h2 {
                        font-size: 18px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}




.growWrapper {

    .topSection {
        margin: 100px 0px 110px;

        h1 {
            color: #2C2C2C;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            padding: 0 180px;
            /* 48px */

            &.highlighted {
                color: #DC633B;
            }
        }

        .content {
            display: flex;
            gap: 126px;
            margin-top: 40px;
            align-items: center;

            .left {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 125%;

                ul {
                    li {
                        margin-left: 30px;
                        // margin-bottom: 5px;
                    }
                }
            }

            .right {
                .banner {
                    width: 508px;
                    height: 339px;
                }
            }
        }
    }

    .secondSection {
        padding-top: 80px;
        padding-bottom: 72px;
        background: #DC633B;

        .headerSection {
            margin-bottom: 60px;

            h1 {
                color: #353535;
                text-align: center;
                font-size: 56px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 56px */
            }

            p {
                color: #262626;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                // line-height: 0%; /* 0px */
                margin: 40px auto 0px;
                width: 706px;
            }
        }

        .cardsSection {
            display: flex;
            gap: 30px;

            .cards {
                flex: 1;
                height: 400px;
                border-radius: 20px;
                background: #FFF;
                padding: 20px;

                h1 {
                    color: #000;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128%;
                    /* 23.04px */
                    margin-top: 25px;
                    max-width: 240px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .growWrapper {

        .topSection {
            margin: 43px 0px 50px;

            h1 {
                font-size: 24px;
            }

            .content {
                display: flex;
                flex-direction: column-reverse;
                gap: 30px;
                margin-top: 30px;
                align-items: center;
                padding: 0 16px;

                .left {
                    font-size: 16px;
                    /* 24px */
                }

                .right {
                    .banner {
                        width: 327px;
                        height: 218px;
                    }
                }
            }
        }

        .secondSection {
            padding-top: 60px;
            padding-bottom: 60px;
            padding: 60px 16px;

            .headerSection {
                margin-bottom: 40px;

                h1 {
                    font-size: 32px;
                }

                p {
                    font-size: 16px;
                    margin: 40px auto 0px;
                    width: auto;
                }
            }

            .cardsSection {
                display: flex;
                gap: 30px;

                .cards {
                    height: 350px;
                    border-radius: 17.45px;
                    background: #FFF;
                    padding: 17.45px;

                    h1 {
                        text-align: center;
                        font-size: 27.9px;
                    }

                    p {
                        font-size: 15.7px;
                        text-align: center;
                        /* 23.04px */
                        margin: 0 auto;
                        margin-top: 21px;
                        max-width: 209px;
                    }
                }
            }
        }
    }
}
