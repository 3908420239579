.wrapper{
    height: 800px;
    width: 100vw;
    position: relative;
    background: white;

    @media screen and (max-width: 576px) {
        height: 1600px;
    }

    .contWrapper {

        display: flex;
        gap: 50px;
        padding-top: 100px;
        
        @media screen and (max-width: 576px) {
            flex-direction: column;
            padding-top: 150px;
        }

        .left {
            z-index: 1;
            flex: 0.6;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 50px;

            @media screen and (max-width: 576px) {
                padding-left: 16px;
            }
    
            h1 {
                color: #000;
                font-size: 48px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                text-align: left;
            }
    
            p {
                color: #434343;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                padding: 40px 0px;
            }
    
            .buttonCont {
                width: 175px;
            }
        }
    
        .right {
            z-index: 1;
            flex: 0.4;
            padding-right: 50px;
    
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 16px;
            .mainImage {
                width: 400px;
                margin: auto;
            }
        }
    }

    

    .pattern {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        height: 100%;
    }

    
}