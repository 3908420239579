.container {
    background: #FFF0E5;
    height: 71px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    

    .containerTwo {
        position: relative;
        display: flex;
        padding-left: 100px;
        padding-top: 15px;
        justify-content: space-around;
        align-items: center;

        .mainText {
            display: flex;
            flex-direction: column;
            gap: 5px;
            h3 {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }

            p {
                color: #616161;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }
        }

        .person {
            height: 134px;
            position: absolute;
            bottom: -17px;
            left: 0;
        }

        .icon {
            height: 32px;
            width: 32px;
            cursor: pointer;
        }
    }

    
}

.clicked {
    display: none;
}

@media screen and (max-width: 576px) { 
    .container {
        background: #FFF0E5;
        height: 95px;

        .containerTwo { 
            // flex-direction: column;
            gap: 15px;
            .mainText {
                gap: 5px;
                h3 {
                    color: #000;
                    font-size: 12px;
                }
    
                p {
                    font-size: 10px;
                }
            }
    
            .person {
                height: 134px;
                position: absolute;
                bottom: -25px;
                left: -10px;
            }
    
            .icon {
                height: 16px;
                width: 16px;
            }
        }
    }
}