.wrapper {
    border-radius: 10px;
    border: 1px solid #000;
    background: #F9F9F9;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin: auto 0px;

    .active {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        border-radius: 10px;
        background: #DC633B;
        padding: 10px 37px;
        cursor: pointer;
    }

    .inactive {
        color: #000;
        background: transparent;
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding: 5px 10px;
        margin: auto;

        .active {
            font-size: 13px;
            border-radius: 6px;
            background: #DC633B;
            padding: 6px 12px;
        }

        .inactive {
            color: #000;
            background: transparent;
        }
    }
}
