.wrapper {
    position: relative;

    .leftHand {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 556px;
        height: 236.161px;
    }

    .rightHand {
        position: absolute;
        right: 0;
        bottom: 102px;
        width: 551px;
        height: 275.5px;

    }

    .contentWrapper {
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        min-height: 90vh;

        h1 {
            color: #000;
            text-align: center;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.5%;
            /* 64.12px */

            &.highlighted {
                color: #DC633B;
            }


            &.top {
                margin-top: 130px;
            }
        }

        p {
            color: #494949;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            margin: 40px auto 32px;
            max-width: 700px;
        }
    }
}

@media screen and (max-width: 576px) {
    .wrapper {
        position: relative;

        .leftHand {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 30px;
            width: 105.793px;
            height: 46.88px;
        }

        .rightHand {
            position: absolute;
            right: 0;
            bottom: 30px;
            top: auto;
            width: 105.793px;
            height: 46.88px;

        }

        .contentWrapper {
            min-height: 80vh;
            padding: 0 16px;

            h1 {
                font-size: 36px;
            }

            p {
                font-size: 16px;
                margin: 40px 0 32px;
            }
        }
    }
}
