.wrapper {
  padding: 100px 0;
}
.cta {
  border-radius: 40px;
  border: 1px solid #f89550;
  padding: 14px 20px;
  color: #f89550;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 118%; /* 21.24px */
}
.content {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.heading {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 32px */
}
.desc {
  color: #161616;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 118%; /* 28.32px */
}
