.wrapper {
  padding: 10px 20px;
  border-radius: 10px;
  background: #DC633B;
  border: 1px solid #DC633B;
  display: block;
  color: #fff;
  //margin: 0 auto;

  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  &.outlined {
    background: transparent;
    color: #DC633B;
  }

  &.dark {
    background: #000;
    border: none;
  }

  &.light {
    background: #fff;
    border: none;
    color: #DC633B;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
}
